<div class="body">
  <div class="page vertical-display">
    <div class="header">
      <h1>Prévisualisation du contenu</h1>
      <p-dropdown
        [options]="languageList"
        [(ngModel)]="selectedLanguageId"
        [emptyMessage]="'Aucune langue disponible'"
        optionValue="id"
        optionLabel="name"
        placeholder="Choisir la langue"
        (onChange)="setLanguage()">
      </p-dropdown>
      <!-- <p-dropdown
        [options]="visualisationModeList"
        [(ngModel)]="selectedVisualisationMode"
        (onChange)="setVisualisationMode()">
      </p-dropdown> -->
      <a routerLink="/{{ this.getProjectName() }}/area-management">
        <i tabindex="0" aria-hidden="true" class="bi bi-pencil action-buttons-item" title="Modifier le contenu"></i>
      </a>
    </div>
    
    <p class="disclaimer">Note: Cette version de la prévisualisation est une version préliminaire. Certains rendus peuvent différer de la version disponible sur votre tablette. Le chargement des images et des vidéos peut être long.</p>

    @if (itineraryList) {
      <div class="previsualisation-container">
        <div class="title-container">
          <span class="title">Parcours</span>
        </div>
        <div class="itinerary-list">
          @for (itinerary of itineraryList; track $index) {
            @if (hasSelectedLanguage(itinerary) && selectedLanguageId) {
              <a routerLink="/{{ this.getProjectName() }}/previsualisation/itinerary/{{itinerary.id}}">
                <div class="itinerary-item">
                  <div class="itinerary-info">
                    <div style="display: flex;">
                      <img class="poi-icon" src="assets/images/Point_Localisation_Grey.png" alt="image"/>
                      <span> {{itinerary.countOfPointOfInterest}} étape(s)</span>
                    </div>
                    @if (!displayName(itinerary)) {
                      <div class="itinerary-name placeholder">Nom du parcours</div>
                    } @else {
                      <div class="itinerary-name">{{ displayName(itinerary) }}</div>
                    }
                    @if (!displayDetails(itinerary)) {
                      <div class="itinerary-details placeholder">Détails du parcours</div>
                    } @else {
                      <div class="itinerary-details">{{ displayDetails(itinerary) }}</div>
                    }
                  </div>
                  <img style="height: 65px;" src="assets/images/Arrow_Next_Grey.png" alt="image"/>
                </div>
              </a>
            }
          }
        </div>
        @if (isListEmpty()) {
          <p class="placeholder" style="text-align: center;">Aucun parcours contenant la langue {{displayLanguage()}} n’a été créé</p>
        }
      </div>
    }
  </div>
</div>
