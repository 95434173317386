<p-dialog class="explanation-popup" [(visible)]="visible" (onHide)="closePopup()" [resizable]="false" [draggable]="false" [showHeader]="false">
  <div class="horizontal-display">
    <div class="vertical-display">
      <h2>{{question}}</h2>
      <p>{{explanation}}</p>
    </div>
    @if (imageFileUrl && imageFileUrl !== 'missing') {
      <img [src]="imageFileUrl" alt="image" class="image"/>
    }
  </div>
  <i tabindex="0" aria-hidden="true" class="bi bi-x-circle close-icon" (click)="closePopup()" (keypress)="closePopup()"></i>

</p-dialog>
