import { Injectable } from "@angular/core";
import { AnswerTranslation, MediaAssociation, MediaAssociationTranslation, Module, ModuleTranslation, Question, QuestionTranslation } from "app/Models/module";
import { Observable, tap, Subject } from "rxjs";
import { DataService } from "../Data-Service/data.service";
import { moduleType } from "app/Enumerations/module-type.enum";
import { publishState } from "app/Enumerations/publish-state.enum";
import { Router } from "@angular/router";
import { AuthService } from "../Auth-Service/auth.service";
import { ApiResponse } from "app/Models/apiResponse";
import { responseCode } from "app/Enumerations/response-code.enum";
import { Media } from "app/Models/media";
import { Language } from "app/Models/language";

@Injectable()
export class ModuleService {
  moduleType = new Subject<number>();
  moduleVar!: Module;

  languagesSubject = new Subject<number>();

  //Enumerations
  enumModuleType = moduleType;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
  ) {}

  getModuleConfigInfos(): Partial<Module> {
    return {
      identificationName: this.moduleVar.identificationName,
      pointOfInterestId: this.moduleVar.pointOfInterestId,
      languagesAssociated: this.moduleVar.languagesAssociated
    };
  }

  getModulePageInfos(): Partial<Module> {
    return {
      id: this.moduleVar.id,
      identificationName: this.moduleVar.identificationName,
      publicationDate: this.moduleVar.publicationDate,
      state: this.moduleVar.state,
      readyForPublication: this.moduleVar.readyForPublication,
      lastModificationDate: this.moduleVar.lastModificationDate,
      pointOfInterestId: this.moduleVar.pointOfInterestId,
      pointOfInterestName: this.moduleVar.pointOfInterestName,
      itineraryId: this.moduleVar.itineraryId,
      itineraryName: this.moduleVar.itineraryName,
      languagesAssociated: this.moduleVar.languagesAssociated
    };
  }

  getMultilanguageContentInfos(): Partial<Module> {
    return {
      languagesAssociated: this.moduleVar.languagesAssociated,
      moduleTranslations: this.moduleVar.moduleTranslations,
      mediaSynthesis: this.moduleVar.mediaSynthesis
    };
  }

  getModuleQuestions(): Question[] {
    return this.moduleVar.questions;
  }

  getModuleVar(): Module {
    return this.moduleVar;
  }

  getModuleVarType(): number {
    return this.moduleVar.type;
  }

  getLanguagesAssociatedModuleVar(): number[] {
    return this.moduleVar.languagesAssociated || [];
  }

  savePartialUpdateModuleVar(partialModule: Partial<Module>) {
    const updatedModule = { ...this.moduleVar, ...partialModule };
    this.moduleVar = updatedModule;
  }

  getModuleTranslations(idLanguage: number) {
    const moduleTranslation = this.moduleVar.moduleTranslations.find((translation) => translation.languageId === idLanguage);
    return moduleTranslation;
  }

  getMediaAssociations(): MediaAssociation[] {
    return this.moduleVar.mediaAssociations;
  }

  saveModuleNameAndSynthesis(languageId: number, moduleName: string, textSynthesis: string) {
    const partialModule: Partial<Module> = {
      moduleTranslations: this.moduleVar.moduleTranslations,
    };
    if (partialModule.moduleTranslations) {
      const indexLanguageModifications = partialModule.moduleTranslations.findIndex((mt) => mt.languageId === languageId);
      partialModule.moduleTranslations[indexLanguageModifications].name = moduleName;
      partialModule.moduleTranslations[indexLanguageModifications].synthesis = textSynthesis;
      this.savePartialUpdateModuleVar(partialModule);
    }
  }

  saveQuestion(question: Question, indexQuestion: number) {
    this.moduleVar.questions[indexQuestion] = question;
  }

  answersWithImage(value: boolean, indexQuestion: number) {
    this.moduleVar.questions[indexQuestion].answersWithImages = value;
  }

  saveImageQuestion(media: Media, indexQuestion: number) {
    this.moduleVar.questions[indexQuestion].media = media;
  }

  removeImageQuestion(indexQuestion: number) {
    this.moduleVar.questions[indexQuestion].media = undefined;
    this.moduleVar.questions[indexQuestion].mediaId = undefined;
  }

  saveImageAnswer(media: Media, indexQuestion: number, indexAnswer: number) {
    this.moduleVar.questions[indexQuestion].answers[indexAnswer].media = media;
  }

  removeImageAnswer(indexQuestion: number, indexAnswer: number) {
    this.moduleVar.questions[indexQuestion].answers[indexAnswer].media = undefined;
    this.moduleVar.questions[indexQuestion].answers[indexAnswer].mediaId = undefined;
  }

  setQuizQuestionsModuleVar(questions: Question[]) {
    this.moduleVar.questions = questions;
  }

  resetModuleVar() {
    this.moduleVar = new Module();
  }
  
  setMediaAssociations(mediaAssociations: MediaAssociation[]) {
    this.moduleVar.mediaAssociations = mediaAssociations;
  }

  setModuleCustomTemplateId(id: number) {
    this.moduleVar.moduleCustomTemplateId = id;
  }

  getModuleCustomTemplateId(): number | undefined {
    return this.moduleVar.moduleCustomTemplateId;
  }

  fetchModule(moduleId: string) {
    this.dataService.getModule(moduleId).subscribe({
      next: (module) => {
        this.moduleVar = module;
        
        // Add the languages Ids in this property to remove unused translation when creating/updating module in the database
        this.moduleVar.languagesAssociated = [];
        this.moduleVar.moduleTranslations.forEach((translation) => {
          this.moduleVar.languagesAssociated.push(translation.languageId);
        });
        
        if (module.type === moduleType.Gallery && module.mediaAssociations && module.languagesAssociated) {
          // Add translations on medias associations that doesn't use the translation.
          module.languagesAssociated.forEach((languageId) => {
            module.mediaAssociations.forEach((mediaAssociation) => {
              if (mediaAssociation.mediaAssociationTranslations.findIndex((mat) => mat.languageId === languageId) === -1) {
                mediaAssociation.mediaAssociationTranslations.push(new MediaAssociationTranslation(0, languageId, ""));
              }
            });
          });
        }
        this.moduleType.next(module.type);
      },
      error: (error) => {
        console.log(error.message);
      },
    });
  }

  initializeNewModule(moduleType: number) {
    const module: Module = new Module();
    module.type = moduleType;
    module.state = publishState.NotPublished;
    module.order = -1;
    module.readyForPublication = false;
    this.moduleVar = module;
    this.moduleType.next(module.type);
  }


  modifyLanguageInService(languageId: number) {
    const moduleTypeFromService = this.moduleVar.type;

    // Add or remove a language on languagesAssociated property
    const indexLanguageId = this.moduleVar.languagesAssociated.findIndex((languageAssociatedId) => languageAssociatedId === languageId);
    if (indexLanguageId === -1) {
      this.moduleVar.languagesAssociated.push(languageId);
    } else {
      this.moduleVar.languagesAssociated.splice(indexLanguageId, 1);
    }

    if (this.moduleVar.moduleTranslations) {
      
      const indexLanguage = this.moduleVar.moduleTranslations.findIndex((e) => e.languageId === languageId);
      if (indexLanguage === -1) {
        this.moduleVar.moduleTranslations?.push(new ModuleTranslation(this.moduleVar.id, languageId, "", ""));
        this.moduleVar.moduleTranslations?.sort();
      }
    }

    // Modify languages for mediaAssociations
    if (moduleTypeFromService === moduleType.Gallery && this.moduleVar.mediaAssociations.length > 0) {
      const indexLanguage = this.moduleVar.mediaAssociations[0].mediaAssociationTranslations?.findIndex((e) => e.languageId === languageId);
      if (indexLanguage === -1) {
        this.moduleVar.mediaAssociations.forEach((association) => {
          association.mediaAssociationTranslations?.push(new MediaAssociationTranslation(0, languageId, ""));
        });
      }
    }

    // Modify languages for questions
    if (moduleTypeFromService === moduleType.QuizTrueFalse || moduleTypeFromService === moduleType.QuizMultipleChoice || moduleTypeFromService === moduleType.QuizIncremental) {
      if (this.moduleVar.questions.length > 0 || moduleTypeFromService === this.enumModuleType.QuizIncremental) {
        const indexLanguage = this.moduleVar.questions[0].questionTranslations.findIndex((e) => e.languageId === languageId);

        if (indexLanguage === -1) {
          this.moduleVar.questions.forEach((question) => {
            question.questionTranslations.push(new QuestionTranslation(0, languageId, "", ""));

            if (moduleTypeFromService !== moduleType.QuizTrueFalse) {
              question.answers.forEach((answer) => {
                answer.answerTranslations.push(new AnswerTranslation(0, languageId, "", ""));
              });
            }
          });
        }
      }
    }
    this.languagesSubject.next(languageId);
  }

  isModuleValid() {
    return this.moduleVar.identificationName && this.moduleVar.identificationName.length > 0;
  }

  createModule(): Observable<Module> {

    const module = structuredClone(this.moduleVar);

    if (module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizTrueFalse || module.type === moduleType.QuizIncremental) {
      module.questions.forEach((question) => {
        question.mediaId = question.media?.id;
        question.media = undefined;

        // Clean unused question translations
        for (let i = question.questionTranslations.length - 1; i >= 0; i--) {
          if (module.languagesAssociated.findIndex((languageAssociatedId) => languageAssociatedId === question.questionTranslations[i].languageId) === -1) {
            question.questionTranslations.splice(i, 1);
          }
        }

        if (module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizIncremental) {
          question.answers.forEach((answer) => {
            answer.mediaId = answer.media?.id;
            answer.media = undefined;

            // Clean unused answer translations
            for (let i = answer.answerTranslations.length - 1; i >= 0; i--) {
              if (module.languagesAssociated.findIndex((languageAssociatedId) => languageAssociatedId === answer.answerTranslations[i].languageId) === -1) {
                answer.answerTranslations.splice(i, 1);
              }
            }
          });
        }
      });
      module.mediaSynthesisId = module.mediaSynthesis?.id;
      module.mediaSynthesis = undefined;
    }

    // Clean unused module name translations
    for (let i = module.moduleTranslations.length - 1; i >= 0; i--) {
      if (module.languagesAssociated.findIndex((languageAssociatedId) => languageAssociatedId === module.moduleTranslations[i].languageId) === -1) {
        module.moduleTranslations.splice(i, 1);
      }
    }

    switch (module.type) {
      case moduleType.Gallery: {
        module.mediaAssociations.forEach((mediaAssociation) => {
          if (!mediaAssociation.isMediaNameTranslated) {
            mediaAssociation.mediaAssociationTranslations = [];
          } else {
            // Remove all the unused media association translations
            for (let i = mediaAssociation.mediaAssociationTranslations.length - 1; i >= 0; i--) {
              if (module.languagesAssociated.findIndex((languageAssociatedId) => languageAssociatedId === mediaAssociation.mediaAssociationTranslations[i].languageId) === -1) {
                mediaAssociation.mediaAssociationTranslations.splice(i, 1);
              }
            }
          }
        });
        return this.dataService.createModuleGallery(module).pipe(
          tap((response: Module) => {
            this.moduleVar = response;
          }),
        );
      }
      case moduleType.QuizTrueFalse: {
        return this.dataService.createModuleQuizTrueFalse(module).pipe(
          tap((response: Module) => {
            this.moduleVar = response;
          }),
        );
      }
      case moduleType.QuizMultipleChoice: {
        return this.dataService.createModuleQuizMultipleChoice(module).pipe(
          tap((response: Module) => {
            this.moduleVar = response;
          }),
        );
      }
      case moduleType.QuizIncremental: {
        return this.dataService.createModuleQuizIncremental(module).pipe(
          tap((response: Module) => {
            this.moduleVar = response;
          }),
        );
      }
      case moduleType.Custom: {
        return this.dataService.createModuleCustom(module).pipe(
          tap((response: Module) => {
            this.moduleVar = response;
          }),
        );
      }
      case moduleType.Undefined: {
        return new Observable();
      }
    }
  }

  updateModule(isDuplication: boolean): Observable<ApiResponse<Module>> {

    // Remove all the unused module name translations
    for (let i = this.moduleVar.moduleTranslations.length - 1; i >= 0; i--) {
      if (this.moduleVar.languagesAssociated && this.moduleVar.languagesAssociated.findIndex((languageId) => languageId === this.moduleVar.moduleTranslations[i].languageId) === -1) {
        this.moduleVar.moduleTranslations.splice(i, 1);
      }
    }

    if (this.moduleVar.type === moduleType.Gallery) {
      this.moduleVar.mediaAssociations.forEach((mediaAssociation) => {
        if (!mediaAssociation.isMediaNameTranslated) {
          mediaAssociation.mediaAssociationTranslations = [];
        } else {
          // Remove all the unused media association translations
          for (let i = mediaAssociation.mediaAssociationTranslations.length - 1; i >= 0; i--) {
            if (this.moduleVar.languagesAssociated && this.moduleVar.languagesAssociated.findIndex((languageId) => languageId === mediaAssociation.mediaAssociationTranslations[i].languageId) === -1) {
              mediaAssociation.mediaAssociationTranslations.splice(i, 1);
            }
          }
        }
      });
    } else if (this.moduleVar.type === moduleType.QuizMultipleChoice || this.moduleVar.type === moduleType.QuizTrueFalse || this.moduleVar.type === moduleType.QuizIncremental) {
      
      this.moduleVar.questions.forEach((question) => {
        for (let i = question.questionTranslations.length - 1; i >= 0; i--) {
          if (this.moduleVar.languagesAssociated && this.moduleVar.languagesAssociated.findIndex((languageAssociatedId) => languageAssociatedId === question.questionTranslations[i].languageId) === -1) {
            question.questionTranslations.splice(i, 1);
          }
        }
        if (this.moduleVar.type === moduleType.QuizMultipleChoice || this.moduleVar.type === moduleType.QuizIncremental) {
          question.answers.forEach((answer) => {
            if (!question.answersWithImages && this.moduleVar.type === moduleType.QuizMultipleChoice) {
              answer.mediaId = undefined;
              answer.media = undefined;
            }
            // Clean unused answer translations
            for (let i = answer.answerTranslations.length - 1; i >= 0; i--) {
              if (this.moduleVar.languagesAssociated && this.moduleVar.languagesAssociated.findIndex((languageAssociatedId) => languageAssociatedId === answer.answerTranslations[i].languageId) === -1) {
                answer.answerTranslations.splice(i, 1);
              }
            }
          });
        }
      });
    }

    // The code below clone the module to update and remove the medias associated with mediaId
    // We did this because if we send a media to the backend, it creates an error because ef core wants to create a new media instead of just associate the existing media
    const module = structuredClone(this.moduleVar);
    module.mediaSynthesisId = module.mediaSynthesis?.id;
    module.mediaSynthesis = undefined;

    if (module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizTrueFalse || module.type === moduleType.QuizIncremental) {
      module.questions.forEach((question) => {
        question.mediaId = question.media?.id;
        question.media = undefined;
        if (module.type !== moduleType.QuizTrueFalse) {
          question.answers.forEach((answer) => {
            answer.mediaId = answer.media?.id;
            answer.media = undefined;
          });
        }
      });
    }

    return this.dataService.updateModule(module).pipe(
      tap((response: ApiResponse<Module>) => {
        if (isDuplication === true) {
          // TODO: Implement duplication
          // this.duplicateModule(newModuleWithInfos);
        }
      }),
    );
  }

  associateMedia(newMediaAssociation: MediaAssociation) {
    this.moduleVar.mediaAssociations.push(newMediaAssociation);
  }

  navigate(url: string) {
    const projectName = this.authService.getProjectName();
    this.router.navigateByUrl(`${projectName}/${url}`);
  }
}
