<div class="body">
  <div class="page">
    <div class="header">
      <h1>Liste des parcours</h1>
      <div>
        <a routerLink="/{{ this.getProjectName() }}/previsualisation/itinerary-list">
          <i tabindex="0" aria-hidden="true" class="bi bi-eye action-buttons-item previsualisation-button" title="Prévisualiser le contenu"></i>
        </a>

        <a routerLink="/{{ this.getProjectName() }}/itinerary-management/new">
          <button class="button" title="Créer un nouveau parcours">Créer</button>
        </a>
      </div>
    </div>

    <app-itinerary-list [listOfItineraries]="listOfItineraries" (deleteItineraryEmitter)="deleteItinerary($event)"> </app-itinerary-list>

    @if (listOfItineraries.length === 0 && dataReceived === true) {
      <p>Aucun parcours n'est enregistré dans l'application. Veuillez commencer la création en cliquant sur le bouton créer en haut à droite de l'écran.</p>
    }
  </div>
</div>
