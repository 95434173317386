import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { moduleType } from 'app/Enumerations/module-type.enum';

@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
  styleUrl: './information-popup.component.css'
})
export class InformationPopupComponent implements OnInit {

  @Input() visible: boolean = false;
  @Input() type?: moduleType;

  @Output() closePopupEmitter: EventEmitter<void> = new EventEmitter<void>();


  enumModuleType = moduleType;

  dialogStyle: { [key: string]: string } = {};

  ngOnInit() {
    const width = Math.min(window.innerWidth * 0.6, 512);
    const height = width * 10 / 16;
    this.dialogStyle = {
      width: `${width}px`,
      height: `${height}px`,
      border: `1px solid rgb(187, 187, 187)`
    };
  }

  displayType(): string | undefined {
    switch (this.type) {
      case this.enumModuleType.QuizMultipleChoice:
        return 'Quiz QCM';

      case this.enumModuleType.QuizTrueFalse:
        return 'Quiz Vrai/Faux';

      case this.enumModuleType.QuizIncremental:
        return 'Quiz Incrémental';

      default:
        return undefined;
    }
  }

  displayInstruction() {
    switch (this.type) {
      case this.enumModuleType.QuizMultipleChoice:
        return 'Choisissez la bonne réponse';

      case this.enumModuleType.QuizTrueFalse:
        return 'Indiquez si ces affirmations sont vraies ou fausses';

      case this.enumModuleType.QuizIncremental:
        return 'Choisissez la réponse correspondant à l\'image';

      default:
        return undefined;
    }
  }

  closePopup() {
    this.closePopupEmitter.emit();
  }

  @HostListener('window:beforeunload')
  handleBeforeUnload() {
      localStorage.setItem('visualisationVisible', 'false');
  }

  @HostListener('window:popstate')
  handlePopState() {
      localStorage.setItem('visualisationVisible', 'false');
  }

}

