import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-explanation-popup',
  templateUrl: './explanation-popup.component.html',
  styleUrl: './explanation-popup.component.css'
})
export class ExplanationPopupComponent {

  @Input() visible: boolean = false;
  @Input() question?: string;
  @Input() explanation?: string;
  @Input() imageFileUrl?: string;

  @Output() closePopupEmitter: EventEmitter<void> = new EventEmitter<void>();

  closePopup() {
    this.closePopupEmitter.emit();
  }

}
