import { Component, OnInit } from '@angular/core';
import { Itinerary } from 'app/Models/itinerary';
import { Language } from 'app/Models/language';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';

@Component({
  selector: 'app-itinerary-list-previsualisation-page',
  templateUrl: './itinerary-list-previsualisation-page.component.html',
  styleUrl: './itinerary-list-previsualisation-page.component.css'
})
export class ItineraryListPrevisualisationPageComponent implements OnInit {

  languageList: Language[] = [];
  selectedLanguageId: number = 1;
  visualisationModeList: string[] = ['Tablette'/* ,'Téléphone' */]
  selectedVisualisationMode = 'Tablette'
  itineraryList: Itinerary[] = [];

  dimensions: { [key: string]: string } = {};

  constructor(
    private dataService: DataService,
    private authService: AuthService,
  ) {};

  ngOnInit() {
    this.dataService.getItineraries().subscribe((itineraries) => {
      this.itineraryList = itineraries;
    })
    this.dataService.getLanguages().subscribe((languages) => {
      this.languageList = languages;
    });
    const storedLanguageId = localStorage.getItem('selectedLanguageForVisualisation');
    if (storedLanguageId) {
      this.selectedLanguageId = parseInt(storedLanguageId);
    } else {
      this.selectedLanguageId = this.languageList[0].id;
    }
    const storedVisualisationMode = localStorage.getItem('visualisationMode');
    if (storedVisualisationMode) {
      this.selectedVisualisationMode = storedVisualisationMode;
    }
    this.setPageTitle();
  }

  setLanguage() {
    localStorage.setItem('selectedLanguageForVisualisation', this.selectedLanguageId.toString());
  }

  setVisualisationMode() {
    localStorage.setItem('visualisationMode', this.selectedVisualisationMode.toString());
  }

  hasSelectedLanguage(itinerary: Itinerary): boolean {
    for (const translation of itinerary.itineraryTranslations) {
      if (translation.languageId === this.selectedLanguageId) {
        return true;
      }
    }
    return false;
  }

  displayName(itinerary: Itinerary): string | undefined {
    const selectedTranslation = itinerary.itineraryTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayDetails(itinerary: Itinerary): string | undefined {
    const selectedTranslation = itinerary.itineraryTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.details;
    }
    return undefined;
  }

  displayLanguage(): string | undefined {
    const selectedLanguage = this.languageList.find(l => l.id === this.selectedLanguageId)
    if (selectedLanguage) {
      return selectedLanguage.name;
    }
    return undefined;
  }

  isListEmpty(): boolean {
    for (const itinerary of this.itineraryList) {
      if (this.hasSelectedLanguage(itinerary)) {
        return false;
      }
    }
    return true;
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    document.title = "Prévisualisation";
  }

}
