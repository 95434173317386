import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fileType } from 'app/Enumerations/file-type.enum';
import { moduleType } from 'app/Enumerations/module-type.enum';
import { Language } from 'app/Models/language';
import { Answer, MediaAssociation, Module, ModuleCustomTemplate, Question } from 'app/Models/module';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-module-previsualisation-page',
  templateUrl: './module-previsualisation-page.component.html',
  styleUrl: './module-previsualisation-page.component.css'
})
export class ModulePrevisualisationPageComponent implements OnInit {

  languageList: Language[] = [];
  languagesSelected: Language[] = [];
  selectedLanguageId: number = 1;
  visualisationModeList: string[] = ['Tablette'/* ,'Téléphone' */]
  selectedVisualisationMode = 'Tablette'
  module?: Module;
  currentQuestion: number = 1;
  highestQuestion: number = 1;
  selectedAnswerId?: number;
  selectedAnswerList: number[] = [];
  resultsVisualisation: boolean = false;
  synthesisVisualisation: boolean = false;
  isInformationPopupVisible: boolean = false;
  moduleCustomTemplate?: ModuleCustomTemplate;
  mediaVisualisation: boolean = false;

  questionFileUrls: string[] = [];
  answerFileUrls: string[][] = [];
  synthesisFileUrl: string = '';
  galleryFileUrls: string[] = [];
  selectedFileIndex: number = -1;

  explanationPopupVisible: boolean = false;
  selectedQuestion?: string;
  selectedExplanation?: string;
  selectedFileUrl?: string;

  incAnswerRandomIndexList: number[] = [];

  dimensions: { [key: string]: string } = {};

  enumModuleType = moduleType;
  enumFileType = fileType;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {};

  ngOnInit() {
    const idFromRoute = this.route.snapshot.paramMap.get("id");
    if (idFromRoute) {
      forkJoin([this.dataService.getModule(idFromRoute), this.dataService.getLanguages()]).subscribe(([module, languages]) => {
        this.languageList = languages;
        this.module = module;
        this.languagesSelected = this.setLanguagesSelected();
        if (module.type === this.enumModuleType.QuizMultipleChoice || module.type === this.enumModuleType.QuizTrueFalse || module.type === this.enumModuleType.QuizIncremental) {
          this.isInformationPopupVisible = true;
          for (let i = 0; i < this.module!.questions.length; i++) {
            if (this.module && this.module.questions[i] && this.module.questions[i].media) {
              this.dataService.getFile(this.module.questions[i].media!).subscribe({
                next: (file) => {
                  this.questionFileUrls[i] = URL.createObjectURL(file);
                },
                error: (error) => {
                  console.log(error.message);
                },
              });
            } else {
              this.questionFileUrls[i] = 'missing';
            }
            if (this.module?.type === this.enumModuleType.QuizMultipleChoice || this.module?.type === this.enumModuleType.QuizIncremental) {

              if (!this.answerFileUrls[i]) {
                this.answerFileUrls[i] = [];
              }
              for (let j = 0; j < this.module!.questions[i].answers.length; j++) {
                if (this.module!.questions[i].answers[j].media) {
                  this.dataService.getFile(this.module!.questions[i].answers[j].media!).subscribe({
                    next: (file) => {
                      this.answerFileUrls[i][j] = URL.createObjectURL(file);
                    },
                    error: (error) => {
                      console.log(error.message);
                    },
                  });
                } else {
                  this.answerFileUrls[i][j] = 'missing';
                }
              }
            }
          }
        }
        if (module.type === this.enumModuleType.Custom && this.module.moduleCustomTemplateId) {
          this.dataService.getModuleCustomTemplate(this.module!.moduleCustomTemplateId!.toString()).subscribe((data) => {
            this.moduleCustomTemplate = data;
          })
        }
        if (module.type === this.enumModuleType.Gallery) {
          for (let i = 0; i < this.module!.mediaAssociations.length; i++) {
            if (this.module && this.module.mediaAssociations[i]) {
              if (this.module.mediaAssociations[i].media.fileType === fileType.Image || this.module.mediaAssociations[i].media.fileType === fileType.Pdf) {
                this.dataService.getFile(this.module.mediaAssociations[i].media!).subscribe({
                  next: (file) => {
                    this.galleryFileUrls[i] = URL.createObjectURL(file);
                  },
                  error: (error) => {
                    console.log(error.message);
                  },
                });
              } else {
                this.galleryFileUrls[i] = this.dataService.streamFile(this.module.mediaAssociations[i].media);
              }
            } else {
              this.galleryFileUrls[i] = '';
            }
          }
        }
        if (module.type === this.enumModuleType.QuizIncremental) {
          for (let i = 0; i < module.questions[0].answers.length; i++) {
            this.incAnswerRandomIndexList[i] = i;
          }
          for (let i = this.incAnswerRandomIndexList.length - 1; i > 0; i--) {
            const randomIndex = Math.floor(Math.random() * (i + 1));
            [this.incAnswerRandomIndexList[i], this.incAnswerRandomIndexList[randomIndex]] = [this.incAnswerRandomIndexList[randomIndex], this.incAnswerRandomIndexList[i]];
          }
        }
        if (this.module?.mediaSynthesis) {
          this.dataService.getFile(this.module!.mediaSynthesis).subscribe({
            next: (file) => {
              this.synthesisFileUrl = URL.createObjectURL(file);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
        }
      });
    }
    const storedLanguageId = localStorage.getItem('selectedLanguageForVisualisation');
    if (storedLanguageId) {
      this.selectedLanguageId = parseInt(storedLanguageId);
    } else {
      this.selectedLanguageId = this.languageList[0].id;
    }
    const storedVisualisationMode = localStorage.getItem('visualisationMode');
    if (storedVisualisationMode) {
      this.selectedVisualisationMode = storedVisualisationMode;
    }
    this.setPageTitle();
  }

  setLanguage() {
    localStorage.setItem('selectedLanguageForVisualisation', this.selectedLanguageId.toString());
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList.forEach((language) => {
      if (this.module?.moduleTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    return languageArray;
  }

  setVisualisationMode() {
    localStorage.setItem('visualisationMode', this.selectedVisualisationMode.toString());
  }

  displayModuleName(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayQuestion(index: number): string | undefined {
    const question = this.module?.questions[index];
    const selectedTranslation = question?.questionTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.question;
    }
    return undefined;
  }

  displayExplanation(question: Question): string | undefined {
    const selectedTranslation = question?.questionTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answerExplanation;
    }
    return undefined;
  }

  displayExplanationInc(answer: Answer): string | undefined {
    const selectedTranslation = answer?.answerTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answerExplanation;
    }
    return undefined;
  }

  showExplanationPopup(index: number) {
    this.explanationPopupVisible = true;
    if (this.module?.type === this.enumModuleType.QuizIncremental) {
      this.selectedQuestion = this.displayQuestion(0);
    } else {
      this.selectedQuestion = this.displayQuestion(index);
    }
    this.selectedExplanation = this.displayExplanation(this.module!.questions[index]);
    if (this.module?.type === this.enumModuleType.QuizMultipleChoice && this.module!.questions[index].answers[this.getGoodAnswerIndex(this.module!.questions[index]) - 1].media) {
      this.selectedFileUrl = this.answerFileUrls[index][this.getGoodAnswerIndex(this.module!.questions[index]) - 1];
    } else {
      this.selectedFileUrl = this.questionFileUrls[index];
    }
  }

  showExplanationPopupInc(index: number) {
    this.explanationPopupVisible = true;
    this.selectedQuestion = this.displayQuestion(0);
    this.selectedExplanation = this.displayExplanationInc(this.module!.questions[0].answers[index]);
    this.selectedFileUrl = this.answerFileUrls[0][index];
  }

  displaySynthesis(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.synthesis;
    }
    return undefined;
  }

  displayAnswer(answer: Answer): string | undefined {
    const selectedTranslation = answer?.answerTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  displayAnswerFromIndex(index: number): string | undefined {
    if (this.module?.type === this.enumModuleType.QuizTrueFalse) {
      if (this.selectedAnswerList[index] === 1) {
        return 'Vrai';
      } else {
        return 'Faux';
      }
    }
    const answer = this.module!.questions[index].answers.find(a => a.id === this.selectedAnswerList[index])
    const selectedTranslation = answer?.answerTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  displayAnswerFromId(id: number): string | undefined {
    const answer = this.module!.questions[0].answers.find(a => a.id === id)
    const selectedTranslation = answer?.answerTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  selectAnswer(answerId: number) {
    if (this.selectedAnswerId === answerId || this.isAnswerLocked(answerId)) {
      this.selectedAnswerId = undefined;
    } else {
      this.selectedAnswerId = answerId;
    }
  }

  validateQuestion() {
    if (this.selectedAnswerId != undefined) {
      this.selectedAnswerList.push(this.selectedAnswerId)
      this.selectedAnswerId = undefined;
      if (this.currentQuestion < this.module!.questions.length) {
        this.currentQuestion++;
      } else {
        this.resultsVisualisation = true;
      }
    }
  }

  validateQuestionInc() {
    if (this.selectedAnswerId != undefined) {
      if (this.currentQuestion === this.highestQuestion) {
        this.selectedAnswerList.push(this.selectedAnswerId);
      } else {
        this.selectedAnswerList[this.currentQuestion - 1] = this.selectedAnswerId;
      }
      this.selectedAnswerId = undefined;
      if (this.currentQuestion < this.module!.questions[0].answers.length) {
        if (this.currentQuestion === this.highestQuestion) {
          this.highestQuestion++;
        } else {
          this.selectedAnswerId = this.selectedAnswerList[this.currentQuestion];
        }
        this.currentQuestion++;
      } else {
        this.resultsVisualisation = true;
      }
    }
  }

  nextQuestionInc() {
    if (this.currentQuestion != this.highestQuestion) {
      this.currentQuestion++;
      this.selectedAnswerId = this.selectedAnswerList[this.currentQuestion - 1];
    }
  }

  previousQuestionInc() {
    if (this.currentQuestion != 1) {
      this.currentQuestion--;
      this.selectedAnswerId = this.selectedAnswerList[this.currentQuestion - 1];
    }
  }

  isAnswerLocked(answerId: number | undefined): boolean {
    if (this.module?.type !== this.enumModuleType.QuizIncremental) {
      return false;
    }
    for (let i = 0; i < this.highestQuestion; i++) {
      if (this.selectedAnswerList[i] === answerId && i != this.currentQuestion - 1) {
        return true;
      }
    }
    return false;
  }

  displayScore(): string | undefined {
    let score = 0;
    for (let i = 0; i < this.module!.questions.length; i++) {
      if (this.isRightAnswer(i)) {
        score++;
      }
    }
    return score.toString() + '/' + this.module?.questions.length.toString();
  }

  isRightAnswer(index: number): boolean {
    if (this.module?.type === this.enumModuleType.QuizTrueFalse) {
      if (this.module?.questions[index].correctAnswer === this.selectedAnswerList[index]) {
        return true;
      }
    } else {
      const correctAnswer = this.getGoodAnswerIndex(this.module!.questions[index]);
      if (correctAnswer != -1 && this.selectedAnswerList[index] === this.module?.questions[index].answers[correctAnswer - 1].id) {
        return true;
      }
    }
    return false;
  }

  isRightAnswerInc(index: number): boolean {
    if(this.module?.questions[0].answers[index].id === this.selectedAnswerList[index]) {
      return true;
    } else {
      return false;
    }
  }

  displayGoodAnswer(index: number): string | undefined {
    if (this.module?.type === this.enumModuleType.QuizTrueFalse) {
      switch (this.module?.questions[index].correctAnswer) {
        case 1:
          return 'Vrai';

        case 2:
          return 'Faux';

        default:
          return ' ';
      }
    }
    const correctAnswer: number = this.getGoodAnswerIndex(this.module!.questions[index]);
    const answer = this.module?.questions[index].answers[correctAnswer - 1];
    const selectedTranslation = answer?.answerTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  getGoodAnswerIndex(question: Question): number {
    switch (question.correctAnswer) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 4:
        return 3;
      case 8:
        return 4;
    }
    return -1;
  }

  toggleSynthesisVisualisation() {
    if (this.synthesisVisualisation) {
      this.synthesisVisualisation = false;
    } else {
      this.synthesisVisualisation = true;
    }
  }

  displayMediaName(media: MediaAssociation): string | undefined {
    const selectedTranslation = media?.mediaAssociationTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return media.media.name;
  }

  setSelectedMedia(index: number) {
    this.selectedFileIndex = index;
    this.mediaVisualisation = true;
  }

  downloadPdf() {
    const link = document.createElement("a");
    if (this.galleryFileUrls[this.selectedFileIndex] && this.module) {
      link.href = this.galleryFileUrls[this.selectedFileIndex];
      link.download = this.module.mediaAssociations[this.selectedFileIndex].media.name + this.module.mediaAssociations[this.selectedFileIndex].media.extension;
      link.click();
      link.remove();
    }
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    document.title = "Prévisualisation";
  }

}
