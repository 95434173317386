import { Component } from "@angular/core";
import { MessageService } from "primeng/api";
import { filter } from "rxjs";
import { NavigationEnd, Event, Router } from "@angular/router";
import { AuthService } from "./Services/Auth-Service/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [MessageService],
})
export class AppComponent {
  title = "OHRIZON CMS";
  localesList = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
  ];

  displayNavbar: boolean = false;
  projectName: string = "Hello";
  showPanel: boolean = false;

  constructor(private router: Router,
    private authService: AuthService
  ) {
    // this.titleService.setTitle($localize`${this.title}`);

    // Check the route to display the navbar
    router.events.pipe(filter((e: Event | NavigationEnd): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      if (e.urlAfterRedirects === "/login" 
        || e.urlAfterRedirects.startsWith("/confirm-email") 
        || e.urlAfterRedirects.startsWith("/reset-password")
        || e.urlAfterRedirects.startsWith("/set-password")
        || e.urlAfterRedirects.startsWith("/password-forgotten")
        || (e.urlAfterRedirects === "/project-selection" && this.router.lastSuccessfulNavigation?.extras.state?.['fromLoginPage'])) {
        //TODO: change /login for a variable
        this.displayNavbar = false;
      } else {
        this.displayNavbar = true;
      }
    });
  }

  getUserInitials(): string {
    return this.authService.getUserInitials();
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  getUserFullName(): string {
    return this.authService.getUserFullName();
  }

  getUserEmail(): string {
    return this.authService.getUserEmail();
  }
}
