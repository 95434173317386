import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItineraryDetail } from 'app/Models/itineraryDetail';
import { Language } from 'app/Models/language';
import { PointOfInterest } from 'app/Models/point-of-interest';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-itinerary-previsualisation-page',
  templateUrl: './itinerary-previsualisation-page.component.html',
  styleUrl: './itinerary-previsualisation-page.component.css'
})
export class ItineraryPrevisualisationPageComponent implements OnInit {

  languageList: Language[] = [];
  languagesSelected: Language[] = [];
  selectedLanguageId: number = 1;
  visualisationModeList: string[] = ['Tablette'/* ,'Téléphone' */]
  selectedVisualisationMode = 'Tablette'
  itinerary?: ItineraryDetail;

  dimensions: { [key: string]: string } = {};

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {};

  ngOnInit() {
    const idFromRoute = this.route.snapshot.paramMap.get("id");
    if (idFromRoute) {
      forkJoin([this.dataService.getItinerary(parseInt(idFromRoute)), this.dataService.getLanguages()]).subscribe(([itinerary, languages]) => {
        this.languageList = languages;
        this.itinerary = itinerary;
        this.languagesSelected = this.setLanguagesSelected();
      });
    }
    const storedLanguageId = localStorage.getItem('selectedLanguageForVisualisation');
    if (storedLanguageId) {
      this.selectedLanguageId = parseInt(storedLanguageId);
    } else {
      this.selectedLanguageId = this.languageList[0].id;
    }
    const storedVisualisationMode = localStorage.getItem('visualisationMode');
    if (storedVisualisationMode) {
      this.selectedVisualisationMode = storedVisualisationMode;
    }
    this.setPageTitle();
  }

  setLanguage() {
    localStorage.setItem('selectedLanguageForVisualisation', this.selectedLanguageId.toString());
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList.forEach((language) => {
      if (this.itinerary?.itineraryTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    return languageArray;
  }

  setVisualisationMode() {
    localStorage.setItem('visualisationMode', this.selectedVisualisationMode.toString());
  }

  displayName(pointOfInterest: PointOfInterest): string | undefined {
    const selectedTranslation = pointOfInterest.pointOfInterestTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayDetails(pointOfInterest: PointOfInterest): string | undefined {
    const selectedTranslation = pointOfInterest.pointOfInterestTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.details;
    }
    return undefined;
  }

  displayItineraryName(): string | undefined {
    const selectedTranslation = this.itinerary?.itineraryTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    document.title = "Prévisualisation";
  }

}
