<div class="body">
  <div class="page">
    <div class="header">
      @switch (pageAction) {
        @case (enumPageAction.Creation) {
          <h1>Création d'une activité</h1>
          <button class="button" (click)="createModule()" title="Créer l'activité">Créer</button>
        }
        @case (enumPageAction.Modification) {
          <div>
            <div class="module-name-state">
              <h1 class="truncate-one-line">{{ modulePageInfos?.identificationName }}</h1>
              @if (modulePageInfos && modulePageInfos.state) {
                <app-publication-state class="publication-state-margin single-line" [publicationState]="modulePageInfos.state" [entityType]="enumEntityType.Module"> </app-publication-state>
              }
            </div>
            @if (modulePageInfos?.publicationDate) {
              <p>Dernière publication: {{ modulePageInfos?.publicationDate | date: "yyyy-MM-dd' 'HH:mm:ss" }}</p>
            }
            @if (modulePageInfos?.lastModificationDate) {
              <p>Dernière modification: {{ modulePageInfos?.lastModificationDate | date: "yyyy-MM-dd' 'HH:mm:ss" }}</p>
            }

            @if (modulePageInfos && modulePageInfos.pointOfInterestName && modulePageInfos.itineraryName) {
              <div class="breadcrumbs">
                <a href="{{ this.getProjectName() }}/itinerary-management/{{ modulePageInfos.itineraryId }}" title="Parcours associé : {{ modulePageInfos.itineraryName }}">
                  {{ modulePageInfos.itineraryName }}
                </a>
                <span> > </span>
                <a href="{{ this.getProjectName() }}/point-of-interest-management/{{ modulePageInfos.pointOfInterestId }}" title="Étape associée : {{ modulePageInfos.pointOfInterestName }}">
                  {{ modulePageInfos.pointOfInterestName }}
                </a>
                <span> > </span>
                <a title="Activité : {{ modulePageInfos.identificationName }}"> {{ modulePageInfos.identificationName }} </a>
              </div>
            } @else if (pageAction === enumPageAction.Modification) {
              <div class="placeholder-space"></div>
            }
          </div>
          <div>
            @if (modulePageInfos) {
              <a routerLink="/{{ this.getProjectName() }}/previsualisation/module/{{modulePageInfos.id}}">
                <i tabindex="0" aria-hidden="true" class="bi bi-eye action-buttons-item previsualisation-button" title="Prévisualiser le contenu"></i>
              </a>
            }
            <p-splitButton label="Sauvegarder" title="Sauvegarder l'activité" (onClick)="updateModule(false)" [model]="menuItems"></p-splitButton>
          </div>
        }
        @case (enumPageAction.TypeSelection) {
          <h1>Création d'une activité</h1>
        }
        @case (enumPageAction.ListVisualisation) {
          <h1>Liste des activités</h1>
          <button class="button" (mouseup)="navigateLink($event, 'module-management/type-selection')" title="Créer une nouvelle activité">Créer</button>
        }
      }
    </div>

    @if (pageAction === enumPageAction.Creation || pageAction === enumPageAction.Modification) {
      <app-module-configuration [pageAction]="pageAction" [pointOfInterestList]="pointOfInterestList" [languagesList]="languagesList"> </app-module-configuration>

      <app-module-multilanguage-content [pageAction]="pageAction" [mediaList]="mediaList" [languagesList]="languagesList"> </app-module-multilanguage-content>
      @if (modulePageInfos?.type === enumModuleType.Custom || this.typeModuleSelected === enumModuleType.Custom) {
        <app-module-custom [moduleCustomTemplates]="moduleCustomTemplates"> </app-module-custom>
      }
    }

    @if (pageAction === enumPageAction.TypeSelection) {
      <div class="cardContainer">
        <!-- TODO: remove aria-hidden -->
        <div tabIndex="0" aria-hidden="true" class="cardTypeModule" title="Galerie multimédia" (click)="showForm(enumModuleType.Gallery)">
          <i class="bi bi-images icon-card"></i>
          <h3>{{ moduleTypeToString(enumModuleType.Gallery) }}</h3>
          <i class="bi bi-arrow-right-short arrow"></i>
        </div>

        <div tabIndex="0" aria-hidden="true" class="cardTypeModule" title="Quiz vrai faux." (click)="showForm(enumModuleType.QuizTrueFalse)">
          <i class="bi bi-patch-question icon-card"></i>
          <h3>{{ moduleTypeToString(enumModuleType.QuizTrueFalse) }}</h3>
          <i class="bi bi-arrow-right-short arrow"></i>
        </div>

        <div tabIndex="0" aria-hidden="true" class="cardTypeModule" title="Quiz à quatre réponses." (click)="showForm(enumModuleType.QuizMultipleChoice)">
          <i class="bi bi-play-btn icon-card"></i>
          <h3>{{ moduleTypeToString(enumModuleType.QuizMultipleChoice) }}</h3>
          <i class="bi bi-arrow-right-short arrow"></i>
        </div>

        <div tabIndex="0" aria-hidden="true" class="cardTypeModule" title="Quiz à question incrémentale." (click)="showForm(enumModuleType.QuizIncremental)">
          <i class="bi bi-play-btn icon-card"></i>
          <h3>{{ moduleTypeToString(enumModuleType.QuizIncremental) }}</h3>
          <i class="bi bi-arrow-right-short arrow"></i>
        </div>

        <div tabIndex="0" aria-hidden="true" class="cardTypeModule" title="Activité spéciale." (click)="showForm(enumModuleType.Custom)">
          <i class="bi bi-gear icon-card"></i>
          <h3>{{ moduleTypeToString(enumModuleType.Custom) }}</h3>
          <i class="bi bi-arrow-right-short arrow"></i>
        </div>
      </div>
    }

    @if (pageAction === enumPageAction.ListVisualisation) {
      <p-table
        [value]="filteredModuleList"
        [rows]="10"
        selectionMode="multiple"
        [(selection)]="selectedModuleList"
        [selectionPageOnly]="true"
        [paginator]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} à {last}, {totalRecords} activités"
        [rowsPerPageOptions]="[10, 20, 50]"
        sortField="createdAt"
        [sortOrder]="-1">
        <ng-template pTemplate="caption">
          <div class="flex">
            <input pInputText [(ngModel)]="filterValue" type="text" (input)="applyGlobalFilter()" placeholder="Rechercher" type="search" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox title="Sélectionner toutes les activités de la page"></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="name">Nom <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="createdAt">Crée le <p-sortIcon field="createdAt"></p-sortIcon></th>
            <th pSortableColumn="readyForPublication">État pour publication <p-sortIcon field="readyForPublication"></p-sortIcon></th>
            <th style="width: 8rem" pSortableColumn="type">Type <p-sortIcon field="type"></p-sortIcon></th>
            <th style="width: 4rem">
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-trash btn-remove-file"
                title="Supprimer les activités sélectionnées"
                (click)="showConfirmationPopup(0, true); $event.stopPropagation()"
                (keypress)="showConfirmationPopup(0, true); $event.stopPropagation()">
              </i>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-module>
          <tr class="table" (mouseup)="navigateLink($event, 'module-management/' + module.id)">
            <td>
              <p-tableCheckbox title="Sélectionner cette activité" [value]="module" (mouseup)="$event.stopPropagation()"></p-tableCheckbox>
            </td>
            <td>{{ module.identificationName }}</td>
            <td>{{ module.createdAt | date: "yyyy-MM-dd' 'HH:mm:ss" }}</td>
            <td>
              <app-completion-state class="margin-left" [completionState]="module.readyForPublication" [tooltipTrue]="'L\'activité est complète'" [tooltipFalse]="'L\'activité n\'est pas complète'">
              </app-completion-state>
            </td>
            <td>{{ moduleTypeToString(module.type) }}</td>
            <td>
              <i
                tabIndex="0"
                aria-hidden="true"
                class="bi bi-trash btn-remove-file"
                title="Supprimer cette activité"
                (click)="showConfirmationPopup(module.id, false)"
                (mouseup)="$event.stopPropagation()"
                (keypress)="showConfirmationPopup(module.id, false); $event.stopPropagation()">
              </i>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">Aucune activité n'est enregistrée dans l'application.</td>
          </tr>
        </ng-template>
      </p-table>
    }
  </div>
</div>

<app-confirmation-popup
  [visible]="isConfirmationPopupVisible"
  [confirmTypeAction]="confirmTypeAction"
  [entityTypeAction]="entityTypeAction"
  [multipleSelection]="multipleDeletion"
  (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>
