import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { inputLength } from "app/ConfigVariables/input-length";
import { administrationType } from "app/Enumerations/administration-type.enum";
import { responseCode } from "app/Enumerations/response-code.enum";
import { Language } from "app/Models/language";
import { ModuleCustomAssociation, ModuleCustomTemplate } from "app/Models/module";
import { BuildPlatform, Project } from "app/Models/project";
import { User } from "app/Models/user";
import { DataService } from "app/Services/Data-Service/data.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-administation-page",
  templateUrl: "./administation-page.component.html",
  styleUrl: "./administation-page.component.css",
})
export class AdministationPageComponent implements OnInit {
  administrationType: number = 1;
  modeCreation = false;
  projects: Project[] = [];
  users: User[] = [];
  buildPlatforms: BuildPlatform[] = [];
  moduleCustomList: ModuleCustomTemplate[] = [];
  modifications: ModuleCustomAssociation[] = [];
  languages: Language[] = [];

  rolesList = ["Administrator", "Client"];

  indexProjectToModify = -1;
  indexUserToModify = -1;

  requestInProgress: boolean = false;
  sendConfirmationEmailClient: number = -1;

  //Forms
  userForm: FormGroup;
  projectName: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minProjectName)]);
  projectNameTable: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minProjectName)]);
  projectBuildPlatforms: FormControl = new FormControl("");
  projectBuildPlatformsTable: FormControl = new FormControl("");
  userFirstName: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minUsername)]);
  userSurname: FormControl = new FormControl("", [Validators.required, Validators.minLength(inputLength.minUsername)]);
  userRole: FormControl = new FormControl("");
  userProjects: FormControl = new FormControl("");
  moduleCustomForm: FormGroup;
  newModuleCustomForm: FormGroup;
  moduleCustomRows: FormArray | undefined;
  moduleCustomFormBeforeModifications: boolean[][] = [];

  //Enumerations
  enumAdministrationType = administrationType;

  //Confirmation popup
  visiblePopup: boolean = false;

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private fb: FormBuilder,
  ) {
    this.userForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.minLength(inputLength.minUsername)]],
      surname: ["", [Validators.required, Validators.minLength(inputLength.minUsername)]],
      emailAddress: ["", [Validators.email]],
      role: ["", Validators.required],
      projectAssociated: ["", Validators.required],
    });

    this.moduleCustomForm = this.fb.group({
      moduleCustomRows: new FormArray([]),
    });

    this.newModuleCustomForm = this.fb.group({
      moduleKey: ["", [Validators.required, Validators.minLength(inputLength.minModuleCustomKey)]],
      name: ["", [Validators.required, Validators.minLength(inputLength.minModuleCustomName)]],
      description: [""],
    });
  }

  ngOnInit() {
    this.dataService.getProjects().subscribe((projects) => {
      this.projects = projects;
    });

    this.dataService.GetUsersAndTheirProjects().subscribe((users) => {
      this.users = users;
    });

    this.dataService.getModuleCustomList().subscribe((modules) => {
      this.moduleCustomList = modules;
    });

    this.dataService.getBuildPlatforms().subscribe((buildPlatforms) => {
      this.buildPlatforms = buildPlatforms;
    });

    this.dataService.getLanguages().subscribe((languages) => {
      this.languages = languages;
    });
    this.setPageTitle();
  }

  createUser() {
    const formRaw = this.userForm.getRawValue();
    if (this.userForm.valid) {
      this.requestInProgress = true;
      const newUser = new User(0, formRaw.emailAddress, formRaw.firstName, formRaw.surname, formRaw.role, formRaw.projectAssociated);
      this.dataService.createUser(newUser).subscribe((response) => {
        this.requestInProgress = false;
        if (response.errorCode === responseCode.SuccessfulCreation) {
          this.userForm.reset();
          this.users.push(response.data);
          this.messageService.clear();
          this.messageService.add({ severity: "success", summary: "Utilisateur créé", detail: "La création de l'utilisateur a réussi." });
          this.modeCreation = false;
        } else {
          this.messageService.add({ severity: "error", summary: "Erreur", detail: response.errorMessage });
        }
      });
    } else {
      if (!this.userForm.get("emailAddress")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Utilisateur non valide", detail: "L'adresse email est invalide." });
      } else if (!this.userForm.get("surname")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom de l'utilisateur doit comporter " + inputLength.minUsername + " caractères minimum." });
      } else if (!this.userForm.get("firstName")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le prénom de l'utilisateur doit comporter " + inputLength.minUsername + " caractères minimum." });
      } else if (!this.userForm.get("role")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Utilisateur non valide", detail: "Veuillez choisir un role pour l'utilisateur." });
      } else if (!this.userForm.get("projectAssociated")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Utilisateur non valide", detail: "Veuillez associer un projet à l'utilisateur." });
      }
    }
  }

  changeTab(administrationType: number) {
    this.modeCreation = false;
    this.administrationType = administrationType;
    if (administrationType === this.enumAdministrationType.ModuleCustom && this.moduleCustomForm.getRawValue().moduleCustomRows.length !== this.moduleCustomList.length) {
      this.addRows(this.moduleCustomList.length, this.projects.length, true);
      this.moduleCustomFormBeforeModifications = this.moduleCustomForm.getRawValue().moduleCustomRows.map((item: { columns: number }) => item.columns);
    }
  }

  get rows(): FormArray {
    return this.moduleCustomForm.get("moduleCustomRows") as FormArray;
  }

  getColumns(row: number): FormArray {
    return this.rows.at(row).get("columns") as FormArray;
  }

  addRows(rowCount: number, columnCount: number, firstInstanciation: boolean) {
    for (let i = 0; i < rowCount; i++) {
      this.rows.push(
        this.fb.group({
          columns: this.fb.array([]),
        }),
      );

      if (firstInstanciation) {
        this.addColumns(i, columnCount, firstInstanciation);
      } else {
        this.addColumns(this.moduleCustomList.length, columnCount, firstInstanciation);
      }
    }
  }

  addColumns(row: number, columnCount: number, firstInstanciation: boolean) {
    const columns = this.getColumns(row);
    for (let j = 0; j < columnCount; j++) {
      const indexProject = this.moduleCustomList[row].projects.findIndex((p) => p.id == this.projects[j].id);
      if (!firstInstanciation || indexProject === -1) {
        columns.push(new FormControl(false));
      } else {
        columns.push(new FormControl({ value: true, disabled: this.moduleCustomList[row].projects[indexProject].isModuleUsedInProject || false }));
      }
    }
  }

  saveModuleAssociations() {
    const arrayAfterModifications = this.moduleCustomForm.getRawValue();
    for (let i = 0; i < this.moduleCustomList.length; i++) {
      for (let j = 0; j < this.projects.length; j++) {
        if (this.moduleCustomFormBeforeModifications[i][j] !== arrayAfterModifications.moduleCustomRows[i].columns[j]) {
          this.modifications.push(new ModuleCustomAssociation(this.moduleCustomList[i].id, this.projects[j].id, arrayAfterModifications.moduleCustomRows[i].columns[j]));
        }
      }
    }

    if (this.modifications.length === 0) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Aucune modification", detail: "Aucun changement depuis la dernière sauvegarde." });
    } else {
      this.visiblePopup = true;
    }
  }

  processModuleAssociations(confirmAction: boolean) {
    this.visiblePopup = false;
    if (confirmAction) {
      this.dataService.updateModuleCustomAssociations(this.modifications).subscribe(() => {
        this.messageService.clear();
        this.messageService.add({ severity: "success", summary: "Modification réussie", detail: "Les nouvelles associations ont été sauvegardées." });
      });

      this.moduleCustomFormBeforeModifications = this.moduleCustomForm.getRawValue().moduleCustomRows.map((item: { columns: number }) => item.columns);
    } else if (this.modifications.length !== 0) {
      const formArrayRaw = this.moduleCustomForm.getRawValue();
      this.modifications.forEach((association) => {
        const projectIndex = this.projects.findIndex((p) => p.id == association.projectId);
        const moduleIndex = this.moduleCustomList.findIndex((m) => m.id == association.moduleId);
        formArrayRaw.moduleCustomRows[moduleIndex].columns[projectIndex] = !association.isAssociated;
      });
      this.moduleCustomForm.setValue(formArrayRaw);
    }
    this.modifications = [];
  }

  createModuleCustom() {
    if (this.newModuleCustomForm.valid) {
      this.dataService.createModuleCustomTemplate(new ModuleCustomTemplate(0, this.newModuleCustomForm.get("moduleKey")?.value, this.newModuleCustomForm.get("name")?.value, this.newModuleCustomForm.get('description')?.value)).subscribe((module) => {
        this.addRows(1, this.projects.length, false);
        this.moduleCustomList.push(module);
        this.moduleCustomFormBeforeModifications.push(Array<boolean>(this.projects.length).fill(false));
      });
    } else {
      if (!this.newModuleCustomForm.get("moduleKey")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "La clef du module doit comporter " + inputLength.minModuleCustomKey + " caractères minimum." });
      } else if (!this.newModuleCustomForm.get("name")?.valid) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom du module doit comporter " + inputLength.minModuleCustomName + " caractères minimum." });
      }
    }
  }

  messageCheckbox(indexModule: number, indexProject: number) {
    if (this.moduleCustomList[indexModule].projects[indexProject].isModuleUsedInProject) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Action impossible", detail: "Vous ne pouvez pas désassocier ce module, il est utilisé dans ce projet." });
    }
  }

  createProject() {
    if (this.isProjectComplete(false)) {
      const buildPlatforms: BuildPlatform[] = [];
      this.projectBuildPlatforms.value.forEach((buildPlatformId: number) => {
        buildPlatforms.push(this.buildPlatforms[this.buildPlatforms.findIndex((bp) => bp.id === buildPlatformId)]);
      });

      this.dataService.createProject(new Project(0, this.projectName.value, buildPlatforms)).subscribe((project) => {
        this.projectBuildPlatforms.reset();
        this.projects.push(project);
        this.messageService.clear();
        this.messageService.add({ severity: "success", summary: "Projet créé", detail: "La création du projet a réussi." });
        this.modeCreation = false;

        if (this.moduleCustomForm.getRawValue().moduleCustomRows.length) {
          this.moduleCustomList.forEach((module, index) => {
            if (this.moduleCustomForm) this.addColumns(index, 1, false);
            this.moduleCustomFormBeforeModifications[index].push(false);
          });
        }
      });
    }
  }

  modifyProject(indexProject: number) {
    this.indexProjectToModify = indexProject;
    this.projectBuildPlatformsTable.setValue(this.projects[indexProject].buildPlatforms.map((bp) => bp.id));
    this.projectNameTable.setValue(this.projects[indexProject].identificationName);
  }

  modifyUser(indexUser: number) {
    this.indexUserToModify = indexUser;
    this.userFirstName.setValue(this.users[indexUser].firstName);
    this.userSurname.setValue(this.users[indexUser].surname);
    this.userRole.setValue(this.users[indexUser].role);
    this.userProjects.setValue(this.users[indexUser].projects.map((p) => p.id));
  }

  resetProjectModification() {
    this.projectBuildPlatformsTable.reset();
    this.indexProjectToModify = -1;
    this.projectNameTable.reset();
  }

  resetUserModification() {
    this.userRole.reset();
    this.userProjects.reset();
    this.userFirstName.reset();
    this.userSurname.reset();
    this.indexUserToModify = -1;
  }

  updateProject() {
    if (this.isProjectComplete(true)) {
      const projectToUpdate = this.projects[this.indexProjectToModify];
      const buildPlatforms: BuildPlatform[] = [];
      this.projectBuildPlatformsTable.value.forEach((platform: number) => {
        buildPlatforms.push(this.buildPlatforms[this.buildPlatforms.findIndex((bp) => bp.id === platform)]);
      });
      projectToUpdate.buildPlatforms = buildPlatforms;
      projectToUpdate.identificationName = this.projectNameTable.value;
      this.dataService.updateProject(projectToUpdate).subscribe((projectUpdated) => {
        this.projects[this.indexProjectToModify] = projectUpdated;
        this.resetProjectModification();
        this.messageService.add({ severity: "success", summary: "Modification réussie", detail: "Le projet a été mis à jour." });
      });
    }
  }

  updateUser() {
    if (this.isUserComplete()) {
      const user = this.users[this.indexUserToModify];
      const userToUpdate: User = new User(user.id, user.email, this.userFirstName.value, this.userSurname.value, this.userRole.value, this.userProjects.value);
      this.dataService.updateUser(userToUpdate).subscribe((response) => {
        if (response.errorCode === responseCode.SuccessfulUpdate) {
          this.users[this.indexUserToModify].role = this.userRole.value;
          this.users[this.indexUserToModify].firstName = this.userFirstName.value;
          this.users[this.indexUserToModify].surname = this.userSurname.value;
          this.users[this.indexUserToModify].projects = [];
          for (let i = 0; i < this.userProjects.value.length; i++) {
            const projectIndex = this.projects.findIndex((p) => p.id == this.userProjects.value[i]);
            this.users[this.indexUserToModify].projects.push(this.projects[projectIndex]);
          }
          this.resetUserModification();
          this.messageService.add({ severity: "success", summary: "Modification réussie", detail: "L'utilisateur a été mis à jour." })
        } else if (response.errorCode === responseCode.NotModified) {
          this.messageService.clear();
          this.messageService.add({ severity: "warn", summary: response.errorMessage, detail: response.data });
          this.resetUserModification();
        }
      });
    }
  }

  isProjectComplete(modificationFromTable: boolean): boolean {
    if ((modificationFromTable && !this.projectNameTable.valid) || (!modificationFromTable && !this.projectName.valid)) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom du projet doit comporter " + inputLength.minProjectName + " caractères minimum." });
      return false;
    } else if ((modificationFromTable && this.projectBuildPlatformsTable.value.length === 0) || (!modificationFromTable && this.projectBuildPlatforms.value.length === 0)) {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le projet doit comporter au moins une plateforme de build." });
      return false;
    } else {
      return true;
    }
  }

  isUserComplete(): boolean {
    this.messageService.clear();
    if (this.userProjects.value.length === 0) {
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "L'utilisateur doit être associé à au moins un projet." });
    } else if (!this.userFirstName.valid) {
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le prénom de l'utilisateur doit comporter au moins " + inputLength.minUsername + " caractères." });
    } else if (!this.userSurname.valid) {
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Le nom de l'utilisateur doit comporter au moins " + inputLength.minUsername + " caractères." });
    } else {
      return true;
    }
    return false;
  }

  setPageTitle() {
    document.title = "Administration";
  }

  sendConfirmAndSetPasswordEmail(indexUser: number, email: string) {
    this.sendConfirmationEmailClient = indexUser;
    this.dataService.sendConfirmAndSetPasswordEmail(email).subscribe((response) => {
      if (response.errorCode === responseCode.SuccessfulCreation) {
        this.sendConfirmationEmailClient = -1;
        this.messageService.add({ severity: "success", summary: "Email envoyé", detail: "L'utilisateur a reçu un nouveau mail de confirmation de compte." });
      }
    });
  }

  displayBoolean(bool: boolean) {
    if (bool){
      return "Oui";
    } else {
      return "Non";
    }
  }
}
