import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountSetupPageAction } from 'app/Enumerations/account-setup-page-action';
import { responseCode } from 'app/Enumerations/response-code.enum';
import { DataService } from 'app/Services/Data-Service/data.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-account-setup-page',
  templateUrl: './account-setup-page.component.html',
  styleUrl: './account-setup-page.component.css'
})
export class AccountSetupPageComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  isReseting = false;
  userId: number = 0;
  token: string = "";
  userEmail: string = "";

  pageAction: number = 0;
  emailConfirmationInProgress: boolean = true;

  // Enumerations
  enumAccountSetupPageAction = AccountSetupPageAction;
  
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dataService: DataService,
    private messageService: MessageService,
    private router: Router) {
    this.resetPasswordForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      passwordConfirmation: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.token = params['token'];
      this.userEmail = params['email'];
      
      if (this.route.snapshot.url[0].path === "set-password") {
        this.resetPasswordForm.get('email')?.setValue(history.state.userEmail);
        this.pageAction = AccountSetupPageAction.setFirstPassword;
        document.title = "Ajouter mon mot de passe";
      } else if (this.route.snapshot.url[0].path === "reset-password") {
        this.resetPasswordForm.get('email')?.setValue(params['email']);
        this.pageAction = AccountSetupPageAction.resetPassword;
        document.title = "Réinitialisation du mot de passe";
      } else if (this.route.snapshot.url[0].path === "password-forgotten") {
        this.pageAction = AccountSetupPageAction.email;
        document.title = "Mot de passe oublié";
      }
    });
  }

  isValidPassWord(): boolean {
    return this.validateLowerCaseChar() && this.validateUpperCaseChar() && this.hasNumber() && this.checkPasswordLength(7);
  }

  setPassword(isResetPasswordAction: boolean) {
    this.isReseting = true;
    const password = this.resetPasswordForm.get('password')?.value;
    const passwordConfirmation = this.resetPasswordForm.get('passwordConfirmation')?.value;

    if (password === passwordConfirmation && this.isValidPassWord()) {
      if (isResetPasswordAction) {
        this.dataService.resetPassword(this.userId, this.token, password).subscribe((response) => {
          this.isReseting = false;
          if (response.errorCode === responseCode.SuccessfulPasswordReset) {
            this.resetPasswordForm.reset();
            this.messageService.add({ severity: "success", summary: "Mot de passe réinitialisé", detail: "Votre mot de passe a été changé." });
            this.router.navigateByUrl('login');
          } else {
            this.messageService.add({ severity: "warn", summary: response.errorMessage, detail: response.data });
          }
        });
      } else {
        this.dataService.confirmUserAndSetFirstPassword(this.userId, password, this.token).subscribe((response) => {
          this.isReseting = false;
          if (response.errorCode === responseCode.SuccessfulConfirmEmail) {
            this.resetPasswordForm.reset();
            this.messageService.add({ severity: "success", summary: "Mot de passe créé", detail: "Votre compte a été activé et votre mot de passe a été bien été créé." });
            this.router.navigateByUrl('login');
          } else {
            this.messageService.add({ severity: "warn", summary: response.errorMessage, detail: response.data });
          }
        });
      }
    } else if (!this.isValidPassWord()) {
      this.messageService.add({ severity: "warn", summary: "Données incorrectes", detail: "Le mot de passe n'est pas conforme." }); 
      this.isReseting = false;
    } else {
      this.messageService.add({ severity: "warn", summary: "Données incorrectes", detail: "Les mots de passe entrés sont différents." });
      this.isReseting = false;
    }
  }

  sendResetPasswordEmail() {
    const email = this.resetPasswordForm.get('email');
    if (email && email.valid) {
      this.isReseting = true;
      this.dataService.sendResetPasswordEmail(email.value).subscribe((response) => {
        this.isReseting = false;
        if (response.errorCode === responseCode.ResetPasswordEmailSent){
          this.resetPasswordForm.reset();
          this.messageService.add({ severity: "success", summary: response.errorMessage, detail: response.data });
        } else if (response.errorCode === responseCode.UserNotFound) {
          this.messageService.add({ severity: "error", summary: "Informations non valides", detail: response.errorMessage });
        } else if (response.errorCode === responseCode.ErrorConfirmEmail) {
          this.messageService.add({ severity: "error", summary: response.errorMessage, detail: response.data });
        }
      });
    } else {
      this.messageService.add({ severity: "warn", summary: "Informations non valides", detail: "Entrer un nom d'utilisateur." });
    }
  }

  validateLowerCaseChar() {
    const password = this.resetPasswordForm.get('password')?.value;
    return password.toUpperCase() != password;
  }

  validateUpperCaseChar() {
    const password = this.resetPasswordForm.get('password')?.value;
    return password.toLowerCase() != password;
  }
  
  hasNumber() {
    const password = this.resetPasswordForm.get('password')?.value;
    return /\d/.test(password);
  }

  checkPasswordLength(minLength: number) {
    const password = this.resetPasswordForm.get('password')?.value;
    return password.length > minLength;
  }

  arePasswordEquals() {
    return this.resetPasswordForm.get('password')?.value == this.resetPasswordForm.get('passwordConfirmation')?.value;
  }
}
