import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { moduleType } from 'app/Enumerations/module-type.enum';
import { Language } from 'app/Models/language';
import { Module } from 'app/Models/module';
import { PointOfInterestDetail } from 'app/Models/PointOfInterestDetail';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-point-of-interest-previsualisation-page',
  templateUrl: './point-of-interest-previsualisation-page.component.html',
  styleUrl: './point-of-interest-previsualisation-page.component.css'
})
export class PointOfInterestPrevisualisationPageComponent implements OnInit {

  languageList: Language[] = [];
  languagesSelected: Language[] = [];
  selectedLanguageId: number = 1;
  visualisationModeList: string[] = ['Tablette'/* ,'Téléphone' */]
  selectedVisualisationMode = 'Tablette'
  pointOfInterest?: PointOfInterestDetail;

  dimensions: { [key: string]: string } = {};

  enumModuleType = moduleType;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {};

  ngOnInit() {
    const idFromRoute = this.route.snapshot.paramMap.get("id");
    if (idFromRoute) {
      forkJoin([this.dataService.getPointOfInterest(idFromRoute), this.dataService.getLanguages()]).subscribe(([pointOfInterest, languages]) => {
        this.languageList = languages;
        this.pointOfInterest = pointOfInterest;
        this.languagesSelected = this.setLanguagesSelected();
      });
    }
    const storedLanguageId = localStorage.getItem('selectedLanguageForVisualisation');
    if (storedLanguageId) {
      this.selectedLanguageId = parseInt(storedLanguageId);
    } else {
      this.selectedLanguageId = this.languageList[0].id;
    }
    const storedVisualisationMode = localStorage.getItem('visualisationMode');
    if (storedVisualisationMode) {
      this.selectedVisualisationMode = storedVisualisationMode;
    }
    this.setPageTitle();
  }

  setLanguage() {
    localStorage.setItem('selectedLanguageForVisualisation', this.selectedLanguageId.toString());
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList.forEach((language) => {
      if (this.pointOfInterest?.pointOfInterestTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    return languageArray;
  }

  setVisualisationMode() {
    localStorage.setItem('visualisationMode', this.selectedVisualisationMode.toString());
  }

  displayName(module: Module): string | undefined {
    const selectedTranslation = module.moduleTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayPointOfInterestName(): string | undefined {
    const selectedTranslation = this.pointOfInterest?.pointOfInterestTranslations.find(t => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    document.title = "Prévisualisation";
  }

}
