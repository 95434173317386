<div class="body">
  <div class="page vertical-display">
    <div class="header">
      <h1>Prévisualisation du contenu</h1>
      <p-dropdown
        [options]="languagesSelected"
        [(ngModel)]="selectedLanguageId"
        [emptyMessage]="'Aucune langue disponible'"
        optionValue="id"
        optionLabel="name"
        placeholder="Choisir la langue"
        (onChange)="setLanguage()">
      </p-dropdown>
      <!-- <p-dropdown
        [options]="visualisationModeList"
        [(ngModel)]="selectedVisualisationMode"
        (onChange)="setVisualisationMode()">
      </p-dropdown> -->
      <a routerLink="/{{ this.getProjectName() }}/point-of-interest-management/{{pointOfInterest?.id}}">
        <i tabindex="0" aria-hidden="true" class="bi bi-pencil action-buttons-item" title="Modifier le contenu"></i>
      </a>
    </div>

    <p class="disclaimer">Note: Cette version de la prévisualisation est une version préliminaire. Certains rendus peuvent différer de la version disponible sur votre tablette. Le chargement des images et des vidéos peut être long.</p>
    
    @if (pointOfInterest) {
      <div class="previsualisation-container">
        <a routerLink="/{{ this.getProjectName() }}/previsualisation/itinerary/{{pointOfInterest.itineraryId}}">
          <img src="assets/images/Arrow_Back_Black.png" alt="image" class="arrow"/>
        </a>
        <div class="title-container">
          @if (displayPointOfInterestName()) {
            <span class="title">{{displayPointOfInterestName()}}</span>
          } @else {
            <span class="title placeholder">Nom de l'étape</span>
          }
        </div>
        <div class="module-list">
          @for (module of pointOfInterest.modules; track $index) {
            @if (selectedLanguageId) {
              <div class="module-item">
                <a routerLink="/{{ this.getProjectName() }}/previsualisation/module/{{module.id}}">
                  <div class="square">
                    @switch (module.type) {
                      @case (enumModuleType.Gallery) {
                        <img class="module-icon" src="assets/images/Icon_media.png" alt="image"/>
                      }
                      @case (enumModuleType.QuizMultipleChoice) {
                        <img class="module-icon" src="assets/images/Icon_quiz.png" alt="image"/>
                      }
                      @case (enumModuleType.QuizTrueFalse) {
                        <img class="module-icon" src="assets/images/Icon_quiz.png" alt="image"/>
                      }
                      @case (enumModuleType.QuizIncremental) {
                        <img class="module-icon" src="assets/images/Icon_quiz.png" alt="image"/>
                      }
                      @case (enumModuleType.Custom) {
                        <img class="module-icon" src="assets/images/Icon_activite.png" alt="image"/>
                      }
                    }
                  </div>
                </a>
                @if (!displayName(module)) {
                  <div class="module-name placeholder">Nom de l'activité</div>
                } @else {
                  <div class="module-name">{{ displayName(module) }}</div>
                }
              </div>
            }
          }
        </div>
        @if (pointOfInterest.modules?.length === 0) {
          <p class="placeholder" style="text-align: center;">Aucune activité n’est associée à l’étape</p>
        }
      </div>
    }

  </div>
</div>
