<div class="body">
  <form [formGroup]="resetPasswordForm">
    @if (pageAction === enumAccountSetupPageAction.email) {
      <h1 id="login-text">Mot de passe oublié</h1>
      <p>Saisir votre e-mail pour recevoir un lien de réinitialisation.</p>
      <div class="form-field">
        <span class="p-float-label">
          <input pInputText id="email" formControlName="email" />
          <label htmlFor="email">E-mail</label>
        </span>
      </div>

      <div class="form-button">
        <button [disabled]="isReseting" class="button" (click)="sendResetPasswordEmail()">Confirmer</button>
        @if (isReseting) {
          <p-progressSpinner class="spinner"></p-progressSpinner>
        }
      </div>
    } @else {
      @if (pageAction === enumAccountSetupPageAction.resetPassword) {
        <h1 id="login-text">Réinitialisation du mot de passe</h1>
      } @else {
        <h1 id="login-text">Création de votre premier mot de passe</h1>
      }
      <input hidden="true" pInputText id="email" formControlName="email" />
      <div class="form-field">
        <span class="p-float-label">
          <p-password 
            formControlName="password" 
            [toggleMask]="true" 
            promptLabel="Choisir un mot de passe" 
            weakLabel="Trop simple" 
            mediumLabel="Complexité moyenne" 
            strongLabel="Mot de passe complexe">
            <ng-template pTemplate="footer">
              <ul class="pl-2 ml-2 my-0 leading-normal">
                  <li [ngClass]="{ 'valid': validateLowerCaseChar() }">Au moins une minuscule</li>
                  <li [ngClass]="{ 'valid': validateUpperCaseChar() }">Au moins une majuscule</li>
                  <li [ngClass]="{ 'valid': hasNumber() }">Au moins un chiffre</li>
                  <li [ngClass]="{ 'valid': checkPasswordLength(7) }">Au moins 8 caractères</li>
              </ul>
            </ng-template>
          </p-password>
          <label for="password">Mot de passe</label>
        </span>
      </div>
      <div class="form-field">
        <span class="p-float-label">
          <p-password formControlName="passwordConfirmation" [toggleMask]="true">
            <ng-template pTemplate="content">
              @if (!arePasswordEquals()) {
                <p class="message-password invalid">Les mots de passe sont différents.</p>
              } @else if (!checkPasswordLength(0)) {
                <p class="message-password invalid">Renseigner un  mot de passe.</p>
              } @else {
                <p class="message-password valid">Les mots de passe sont identiques.</p>
              }
            </ng-template>
          </p-password>
          <label for="passwordConfirmation">Confirmer le mot de passe</label>
        </span>
      </div>
      <div class="form-button">
        @if (pageAction === enumAccountSetupPageAction.resetPassword) {
          <button [disabled]="isReseting" class="button" (click)="setPassword(true)">Confirmer</button>
        } @else {
          <button [disabled]="isReseting" class="button" (click)="setPassword(false)">Confirmer</button>
        }
        @if (isReseting) {
          <p-progressSpinner class="spinner"></p-progressSpinner>
        }
      </div>
    }
  </form>
</div>

  