<div class="body">
  <div class="page vertical-display">
    <div class="header">
      <h1>Prévisualisation du contenu</h1>
      <p-dropdown
        [options]="languagesSelected"
        [(ngModel)]="selectedLanguageId"
        [emptyMessage]="'Aucune langue disponible'"
        optionValue="id"
        optionLabel="name"
        placeholder="Choisir la langue"
        (onChange)="setLanguage()">
      </p-dropdown>
      <!-- <p-dropdown
        [options]="visualisationModeList"
        [(ngModel)]="selectedVisualisationMode"
        (onChange)="setVisualisationMode()">
      </p-dropdown> -->
      <a routerLink="/{{ this.getProjectName() }}/module-management/{{module?.id}}">
        <i tabindex="0" aria-hidden="true" class="bi bi-pencil action-buttons-item" title="Modifier le contenu"></i>
      </a>
    </div>

    <p class="disclaimer">Note: Cette version de la prévisualisation est une version préliminaire. Certains rendus peuvent différer de la version disponible sur votre tablette. Le chargement des images et des vidéos peut être long.</p>

    @if (module) {
      <div class="previsualisation-container" [ngClass]="{'black-background' : mediaVisualisation}">
        @if (synthesisVisualisation) {
          <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="toggleSynthesisVisualisation()" (keypress)="toggleSynthesisVisualisation()"/>
        } @else if (mediaVisualisation) {
          <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="mediaVisualisation = false; selectedFileIndex = -1" (keypress)="mediaVisualisation = false; selectedFileIndex = -1"/>
        } @else if (module.pointOfInterestId && !synthesisVisualisation) {
          <a routerLink="/{{ this.getProjectName() }}/previsualisation/point-of-interest/{{module.pointOfInterestId}}">
            @if (module.type === enumModuleType.Gallery) {
              <img src="assets/images/Arrow_Back_Black.png" alt="image" class="arrow"/>
            } @else {
              <img src="assets/images/Arrow_Back.png" alt="image" class="arrow"/>
            }
          </a>
        }

        @switch (module.type) {
          @case (enumModuleType.Custom) {
            <div class="title-container">
              @if (displayModuleName()) {
                <span class="title">{{displayModuleName()}}</span>
              } @else {
                <span class="title placeholder">Nom de l'activité</span>
              }
            </div>
            @switch (moduleCustomTemplate?.moduleKey) {
              @case ('ChartReference2025') {
                <img class="screenshot" src="assets/screenshots/charte-référence-2025.png" alt="image"/>
              }
              @case ('ChartBio') {
                <img class="screenshot" src="assets/screenshots/charte-bio.png" alt="image"/>
              }
              @case ('ChartRotation') {
                <img class="screenshot" src="assets/screenshots/charte-rotation.png" alt="image"/>
              }
              @case ('TempsDeTravail') {
                <img class="screenshot" src="assets/screenshots/temps-de-travail.png" alt="image"/>
              }
              @case ('RangementDuLocal') {
                <img class="screenshot" src="assets/screenshots/rangement-du-local.png" alt="image"/>
              }
              @case ('TravailDuSol') {
                <img class="screenshot" src="assets/screenshots/travail-du-sol.png" alt="image"/>
              }
              @case ('RFU') {
                <img class="screenshot" src="assets/screenshots/RFU.png" alt="image"/>
              }
              @case ('OutilDeDecision') {
                <img class="screenshot" src="assets/screenshots/outil-de-décision.png" alt="image"/>
              }
              @case ('MosaiqueHabitats') {
                <img class="screenshot" src="assets/screenshots/mosaïque-habitats.png" alt="image"/>
              }
              @case ('Sondage') {
                <img class="screenshot" src="assets/screenshots/sondage.png" alt="image"/>
              }
              @case ('PhotoSouvenirGroupe') {
                <img class="screenshot" src="assets/screenshots/photo-souvenir.png" alt="image"/>
              }
              @case ('auxiliairesdescultures') {
                <img class="screenshot" src="assets/screenshots/auxiliaire-des-cultures.png" alt="image"/>
              }
              @case ('MosaiqueHabitatsAuray') {
                <img class="screenshot" src="assets/screenshots/mosaïque-habitats-auray.png" alt="image"/>
              }
              @case ('SyntheseAuray') {
                <img class="screenshot" src="assets/screenshots/synthèse-deux-systèmes.png" alt="image"/>
              }
              @case ('MosaiqueHabitatsAuray') {
                <img class="screenshot" src="assets/screenshots/mosaïque-habitats-auray.png" alt="image"/>
              }
              @case ('FairmontUniforme') {
                <img class="screenshot" src="assets/screenshots/uniforme-pour-fairmont.png" alt="image"/>
              }
              @case ('FairmontChariot') {
                <img class="screenshot" src="assets/screenshots/chariot-pour-fairmont.png" alt="image"/>
              }
              @case ('QuizOral1.3') {
                <img class="screenshot" src="assets/screenshots/les-fourrages.png" alt="image"/>
              }
              @case ('QuizOral3.3') {
                <img class="screenshot" src="assets/screenshots/biosécurité-en-élevage.png" alt="image"/>
              }
              @case ('TrevarezMenu') {
                <img class="screenshot" src="assets/screenshots/menus-de-trevarez.png" alt="image"/>
              }
              @case ('MosaiqueHabitatsCrecom') {
                <img class="screenshot" src="assets/screenshots/mosaïque-habitats-crecom.png" alt="image"/>
              }
              @case (null) {
                <p class="placeholder">Aucun type d'activité n'a été choisi</p>
              }
              @default {
                <p>Aucun aperçu disponible pour ce type d'activité</p>
              }
            }
          }
          @case (enumModuleType.Gallery) {
            @if (mediaVisualisation) {
              <div class="media-visualisation-display horizontal-display-spaced">
                <div class="centered" style="width: 100%; height: 70%; margin-right: 10px; margin-left: 10px;">
                  @switch (module.mediaAssociations[selectedFileIndex].media.fileType) {
                    @case (enumFileType.Image) {
                      <img class="image-selected" [src]="galleryFileUrls[selectedFileIndex]" alt="image"/>
                    }
                    @case (enumFileType.Video) {
                      <video class="image-selected" controls>
                        <source [src]="galleryFileUrls[selectedFileIndex]" type="video/quicktime" />
                        <source [src]="galleryFileUrls[selectedFileIndex]" type="video/mp4" />
                      </video>
                    }
                    @case (enumFileType.Audio) {
                      <audio class="image-selected" controls [src]="galleryFileUrls[selectedFileIndex]"></audio>
                    }
                    @case (enumFileType.Pdf) {
                      <div class="vertical-display image-selected">
                        <button class="button" (click)="downloadPdf()">Télécharger le pdf</button>
                        <span style="color: white">Le pdf sera directement affiché dans l'application</span>
                      </div>
                    }
                  }
                </div>
                <div class="media-visualisation-list">
                  @for (media of module.mediaAssociations; track $index) {
                    <div tabindex="0" aria-hidden="true" class="media-list-element" [ngClass]="{'selected-media-list-element' : selectedFileIndex === $index}" (click)="setSelectedMedia($index)">
                      @if (galleryFileUrls[$index] && media.media.fileType === enumFileType.Image) {
                        <img class="image-fill" style="border-radius: 1rem;" [src]="galleryFileUrls[$index]" alt="image"/>
                      }
                    </div>
                  }
                </div>
              </div>
            } @else {
              <div class="vertical-display">
                <span class="ressources-label">Ressources</span>
                <span>{{displayModuleName()}}</span>
                @if (!displayModuleName()) {
                  <span class="placeholder">Nom de l'activité</span>
                }
              </div>
              <div class="media-list scrollbar">
                @for (media of module.mediaAssociations; track $index) {
                  <div tabindex="0" aria-hidden="true" class="media-container centered" (click)="setSelectedMedia($index)">
                    @if (galleryFileUrls[$index] && media.media.fileType === enumFileType.Image) {
                      <img class="image-fill" [src]="galleryFileUrls[$index]" alt="image"/>
                    }
                    <div class="media-icon-container centered">
                      @switch (media.media.fileType) {
                        @case (enumFileType.Image) {
                          <i class="bi bi-camera-fill media-icon"></i>
                        }
                        @case (enumFileType.Pdf) {
                          <i class="bi bi-file-earmark-fill media-icon"></i>
                        }
                        @default {
                          <i class="bi bi-play-fill media-icon"></i>
                        }
                      }
                    </div>
                    <div class="media-name centered">
                      @if (displayMediaName(media)) {
                        {{displayMediaName(media)}}
                      } @else {
                        <span style="opacity: 1; color: #ff7474;" class="placeholder">Nom du média</span>
                      }
                    </div>
                  </div>
                }
              </div>
              @if (module.mediaAssociations.length === 0) {
                <p class="placeholder" style="text-align: center;">Aucun média n'a été ajouté à cette activité</p>
              }
            }
          }
          @case (enumModuleType.QuizIncremental) {
            @if (!resultsVisualisation) {
              <div class="title-container">
                @if (displayModuleName()) {
                  <span class="title">{{displayModuleName()}}</span>
                } @else {
                  <span class="title placeholder">Nom de l'activité</span>
                }
              </div>
              @if (languagesSelected.length === 0) {
                <p class="placeholder">Aucune langue n'est définie pour cette activité</p>
              } @else {

                <i tabindex="0" aria-hidden="true" class="bi bi-question-circle question-circle" (click)="isInformationPopupVisible = true" (keypress)="isInformationPopupVisible = true"></i>
                @if (!displayQuestion(0)) {
                  <p class="placeholder">Intitulé de la question</p>
                } @else {
                  <p class="question question-inc">{{displayQuestion(0)}}</p>
                }
                <div class="horizontal-display-spaced answers-container answers-container-inc">
                  @if (answerFileUrls[0][currentQuestion - 1]) {
                    @if (answerFileUrls[0][currentQuestion - 1] !== 'missing') {
                      <img class="question-image inc-image" [src]="answerFileUrls[0][currentQuestion - 1]" alt="image"/>
                    } @else {
                      <span class="placeholder" style="margin-left: 5rem;">Image manquante</span>
                    }
                  } @else {
                    <div style="margin-left: 8rem;">
                      <p-progressSpinner></p-progressSpinner>
                    </div>
                  }
                    <div class="answer-list-inc" [ngClass]="{'answer-list-medium-template' : incAnswerRandomIndexList.length > 3 && incAnswerRandomIndexList.length < 7, 'answer-list-big-template' : incAnswerRandomIndexList.length > 6 }">
                      @for (answer of incAnswerRandomIndexList; track $index) {
                        <div tabindex="0" aria-hidden="true" class="answer answer-inc" [ngClass]="{'selected-answer' : selectedAnswerId === module.questions[0].answers[incAnswerRandomIndexList[$index]].id, 'locked-answer' : isAnswerLocked(module.questions[0].answers[incAnswerRandomIndexList[$index]].id), 'small-text': displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]]) && displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]])!.length >= 50}" (click)="selectAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]].id!)" (keypress)="selectAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]].id!)">
                          {{displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]])}}
                          @if (!displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]])) {
                            <span class="placeholder">Réponse</span>
                          }
                        </div>
                      }
                    </div>
                </div>
                <span class="question-count-inc">{{currentQuestion + '/' + module.questions[0].answers.length}}</span>
                <button class="increment-button previous-button" [ngClass]="{'button-disabled' : currentQuestion === 1}" (click)="previousQuestionInc()">Précédent</button>
                <button class="increment-button next-button" [ngClass]="{'button-disabled' : currentQuestion === highestQuestion}" (click)="nextQuestionInc()">Suivant</button>
                <button class="validation-button validation-button-inc" [ngClass]="{'button-disabled' : selectedAnswerId === undefined}" (click)="validateQuestionInc()">Valider</button>
              }
            } @else {
              <div class="title-container">
                @if (displayModuleName()) {
                  <span class="title">{{displayModuleName()}}</span>
                } @else {
                  <span class="title placeholder">Nom de l'activité</span>
                }
              </div>
              @if (!synthesisVisualisation) {
                @if (!displayQuestion(0)) {
                  <p class="placeholder">Intitulé de la question</p>
                } @else {
                  <p class="question-inc">{{displayQuestion(0)}}</p>
                }
                <div class="result-list-inc">
                  @for (answer of module.questions[0].answers; track $index) {
                    <div>
                      <div class="result-image-container-inc centered">
                        @if (answerFileUrls[0][$index]) {
                          @if (answerFileUrls[0][$index] !== 'missing') {
                            <img [src]="answerFileUrls[0][$index]" alt="image" style="border-radius: 1rem;" class="image-fill"/>
                          } @else {
                            <i class="bi bi-card-image placeholder" style="font-size: 3rem;"></i>
                          }
                        } @else {
                          <p-progressSpinner></p-progressSpinner>
                        }
                        @if (answer.answerTranslations[0].answerExplanation) {
                          <img tabindex="0" aria-hidden="true" src="assets/images/i_Icone_1X.png" alt="image" class="explanation-icon explanation-icon-inc" (click)="showExplanationPopupInc($index)" (keypress)="showExplanationPopupInc($index)"/>
                        }
                        <div class="result-image-name centered">
                          @if (displayAnswer(answer)) {
                            {{displayAnswer(answer)}}
                          } @else {
                            <span class="placeholder">Réponse</span>
                          }
                        </div>
                      </div>
                      <div class="result-selected-answer-inc centered" [ngClass]="{'wrong-answer' : !isRightAnswerInc($index)}">
                        @if (displayAnswerFromId(selectedAnswerList[$index])) {
                          {{displayAnswerFromId(selectedAnswerList[$index])}}
                        } @else {
                          <span class="placeholder">Réponse</span>
                        }
                      </div>
                    </div>
                  }
                </div>
                @if (synthesisFileUrl !== '' || module!.moduleTranslations[0].synthesis !== '') {
                  <img tabindex="0" aria-hidden="true" src="assets/images/Synthese_Button.png" alt="image" class="synthesis-icon" (click)="toggleSynthesisVisualisation()" (keypress)="toggleSynthesisVisualisation()"/>
                }
              } @else {
                <div class="synthesis-container">
                  <p> {{displaySynthesis()}} </p>
                  @if (module.mediaSynthesis) {
                    @if (synthesisFileUrl !== '') {
                      <img style="max-height: 100%; max-width: 100%;" [src]="synthesisFileUrl" alt="image"/>
                    } @else {
                      <p-progressSpinner></p-progressSpinner>
                    }
                  }
                </div>
              }
            }
          }
          @default {
            <div class="title-container">
              @if (displayModuleName()) {
                <span class="title">{{displayModuleName()}}</span>
              } @else {
                <span class="title placeholder">Nom de l'activité</span>
              }
            </div>
            @if (!resultsVisualisation) {
              <i tabindex="0" aria-hidden="true" class="bi bi-question-circle question-circle" (click)="isInformationPopupVisible = true" (keypress)="isInformationPopupVisible = true"></i>
            }

            @if (!resultsVisualisation) {
              @if (module.questions.length) {
                @if (!displayQuestion(currentQuestion - 1)) {
                  <p class="placeholder question">Intitulé de la question</p>
                } @else {
                  <p class="question">{{displayQuestion(currentQuestion - 1)}}</p>
                }

                <div class="horizontal-display-spaced answers-container">
                  @if (questionFileUrls[currentQuestion - 1] !== 'missing') {
                    @if (!questionFileUrls[currentQuestion - 1]) {
                      <div style="margin-left: 8rem;">
                        <p-progressSpinner></p-progressSpinner>
                      </div>
                    } @else {
                      <img class="question-image" [src]="questionFileUrls[currentQuestion - 1]" alt="image"/>
                    }
                  }
                  @if (module.type === enumModuleType.QuizMultipleChoice && module!.questions.length && module!.questions[currentQuestion - 1].answersWithImages) {
                    <div class="answer-list-with-media" [ngClass]="{'answers-one-line' : questionFileUrls[currentQuestion - 1] === 'missing'}">
                      @for (answer of module!.questions[currentQuestion - 1].answers; track $index) {
                        <div class="answer-with-media horizontal-display">
                          <div tabindex="0" aria-hidden="true" class="answer-image-container centered" [ngClass]="{'selected-answer-with-image' : selectedAnswerId === answer.id}" (click)="selectAnswer(answer.id!)" (keypress)="selectAnswer(answer.id!)">
                            @if (answerFileUrls[currentQuestion - 1] && answerFileUrls[currentQuestion - 1][$index]) {
                              @if (answerFileUrls[currentQuestion - 1][$index] === 'missing') {
                                <i class="bi bi-card-image placeholder" style="font-size: 3rem;"></i>
                              } @else {
                                <img class="image-fill" [src]="answerFileUrls[currentQuestion - 1][$index]" alt="image"/>
                              }
                            } @else {
                              <p-progressSpinner></p-progressSpinner>
                            }
                          </div>
                          <div class="answer-wtih-media-text">{{displayAnswer(answer)}}</div>
                          @if (!displayAnswer(answer)) {
                            <div class="answer-wtih-media-text placeholder">Réponse</div>
                          }
                        </div>
                      }
                    </div>
                  } @else {
                    <div class="answer-list">
                      @if (module.type === enumModuleType.QuizTrueFalse) {
                        <div tabindex="0" aria-hidden="true" class="answer" [ngClass]="{'selected-answer' : selectedAnswerId === 1}" (click)="selectAnswer(1)" (keypress)="selectAnswer(1)">Vrai</div>
                        <div tabindex="0" aria-hidden="true" class="answer" [ngClass]="{'selected-answer' : selectedAnswerId === 2}" (click)="selectAnswer(2)" (keypress)="selectAnswer(2)">Faux</div>
                      } @else if (module!.questions.length) {
                        @for (answer of module!.questions[currentQuestion - 1].answers; track $index) {
                          <div tabindex="0" aria-hidden="true" class="answer" [ngClass]="{'selected-answer' : selectedAnswerId === answer.id, 'small-text': displayAnswer(answer) && displayAnswer(answer)!.length >= 50}" (click)="selectAnswer(answer.id!)" (keypress)="selectAnswer(answer.id!)">
                            {{displayAnswer(answer)}}
                            @if (!displayAnswer(answer)) {
                              <span class="placeholder">Réponse</span>
                            }
                          </div>
                        }
                      }
                    </div>
                  }
                </div>

                <span class="question-count">{{currentQuestion + '/' + module.questions.length}}</span>

                <button class="validation-button" [ngClass]="{'button-disabled' : selectedAnswerId === undefined}" (click)="validateQuestion()">Valider</button>
              } @else {
                @if (languagesSelected.length > 0) {
                  <p class="placeholder">Aucune question sauvegardée</p>
                } @else {
                  <p class="placeholder">Aucune langue définie pour cette activité</p>
                }
              }
            } @else {
              @if (!synthesisVisualisation) {
                <div class="result-list scrollbar">
                  @for (question of module.questions; track $index) {
                    <div class="result-container" [ngClass]="{'wrong-answer' : !isRightAnswer($index)}">
                      <div class="question-result">
                        <span class="bold">{{displayQuestion($index)}}</span>
                        @if (!displayQuestion($index)) {
                          <span class="placeholder">Intitulé de la question</span>
                        }
                        <div>
                          <span class="bold">Votre réponse : </span>
                          <span>{{displayAnswerFromIndex($index)}}</span>
                          @if (!displayAnswerFromIndex($index)) {
                            <span class="placeholder">Réponse</span>
                          }
                        </div>
                        <span class="bold">La bonne réponse «{{displayGoodAnswer($index)}}
                          @if (!displayGoodAnswer($index)) {
                            <span class="placeholder">Réponse </span>
                          }
                          @if (displayGoodAnswer($index) === ' ') {
                            <span class="placeholder">Aucune bonne réponse sélectionnée </span>
                          }»
                        </span>
                      </div>
                      <div class="horizontal-display">
                          @if (questionFileUrls[$index] !== 'missing') {
                            @if (questionFileUrls[$index]) {
                              <div class="result-image-container centered">
                                <span class="image-label">Question</span>
                                <img class="image-fill" [src]="questionFileUrls[$index]" alt="image"/>
                              </div>
                            } @else {
                              <div class="result-image-container centered">
                                <span class="image-label">Question</span>
                                <p-progressSpinner></p-progressSpinner>
                              </div>
                            }
                          }
                          @if (module.type === enumModuleType.QuizMultipleChoice && answerFileUrls[$index][getGoodAnswerIndex(question) - 1] !== 'missing') {
                            @if (answerFileUrls[$index][getGoodAnswerIndex(question) - 1]) {
                              <div class="result-image-container centered">
                                <span class="image-label">Réponse</span>
                                <img class="image-fill" [src]="answerFileUrls[$index][getGoodAnswerIndex(question) - 1]" alt="image"/>
                              </div>
                            } @else {
                              <div class="result-image-container centered">
                                <span class="image-label">Réponse</span>
                                <p-progressSpinner></p-progressSpinner>
                              </div>
                            }
                          }
                      </div>
                      @if (question.questionTranslations[0].answerExplanation !== '') {
                        <img tabindex="0" aria-hidden="true" src="assets/images/i_Icone_1X.png" alt="image" class="explanation-icon" (click)="showExplanationPopup($index)" (keypress)="showExplanationPopup($index)"/>
                      }
                    </div>
                  }
                </div>
                <div class="score centered">
                  {{displayScore()}}
                  <span>Résultat personnel</span>
                </div>
                @if (synthesisFileUrl !== '' || module!.moduleTranslations[0].synthesis !== '') {
                  <img tabindex="0" aria-hidden="true" src="assets/images/Synthese_Button.png" alt="image" class="synthesis-icon" (click)="toggleSynthesisVisualisation()" (keypress)="toggleSynthesisVisualisation()"/>
                }
              } @else {
                <div class="synthesis-container vertical-display scrollbar">
                  <p style="text-align: center;"> {{displaySynthesis()}} </p>
                  @if (module.mediaSynthesis) {
                    @if (synthesisFileUrl !== '') {
                      <img style="max-height: 100%; max-width: 100%;" [src]="synthesisFileUrl" alt="image"/>
                    } @else {
                      <p-progressSpinner></p-progressSpinner>
                    }
                  }
                </div>
              }
            }
          }
        }
        <app-information-popup
          [visible]="isInformationPopupVisible"
          [type]="module.type"
          (closePopupEmitter)="isInformationPopupVisible = false">
        </app-information-popup>

        <app-explanation-popup
          [question]="selectedQuestion"
          [explanation]="selectedExplanation"
          [imageFileUrl]="selectedFileUrl"
          [visible]="explanationPopupVisible"
          (closePopupEmitter)="explanationPopupVisible = false">
        </app-explanation-popup>
      </div>
    }
  </div>
</div>



