export const inputLength = {
  /*
   * Max lengths below
   */

  // Itineraries
  maxItineraryLabel: 50,
  maxItineraryName: 55,
  maxItineraryDetails: 76,

  // Point of interests
  maxPointOfInterestLabel: 50,
  maxPointOfInterestName: 55,
  maxPointOfInterestDetails: 76,

  // Modules
  maxModuleLabel: 40,
  maxModuleName: 55,
  maxQuizSynthesis: 1000,

  // Questions
  maxQuestionInput: 300,

  // Answers in quiz modules
  maxAnswerInput: 150,
  maxAnswerIncrementalInput: 50,
  maxAnswerExplanation: 500,

  // Medias
  maxMediaLabel: 100,

  /*
   * Min lengths below
   */

  // Projects
  minProjectName: 3,

  // Users
  minUsername: 1,

  // Passwords
  minPassword: 8,

  // Modules Custom
  minModuleCustomKey: 0,
  minModuleCustomName: 0,
};
