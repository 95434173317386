<div class="body">
  <div class="page vertical-display">
    <div class="header">
      <h1>Prévisualisation du contenu</h1>
      <p-dropdown
        [options]="languagesSelected"
        [(ngModel)]="selectedLanguageId"
        [emptyMessage]="'Aucune langue disponible'"
        optionValue="id"
        optionLabel="name"
        placeholder="Choisir la langue"
        (onChange)="setLanguage()">
      </p-dropdown>
      <!-- <p-dropdown
        [options]="visualisationModeList"
        [(ngModel)]="selectedVisualisationMode"
        (onChange)="setVisualisationMode()">
      </p-dropdown> -->
      <a routerLink="/{{ this.getProjectName() }}/itinerary-management/{{itinerary?.id}}">
        <i tabindex="0" aria-hidden="true" class="bi bi-pencil action-buttons-item" title="Modifier le contenu"></i>
      </a>
    </div>

    <p class="disclaimer">Note: Cette version de la prévisualisation est une version préliminaire. Certains rendus peuvent différer de la version disponible sur votre tablette. Le chargement des images et des vidéos peut être long.</p>
    
    @if (itinerary) {
      <div class="previsualisation-container">
        <a routerLink="/{{ this.getProjectName() }}/previsualisation/itinerary-list">
          <img src="assets/images/Arrow_Back_Black.png" alt="image" class="arrow"/>
        </a>
        <div class="title-container">
          @if (displayItineraryName()) {
            <span class="title">{{displayItineraryName()}}</span>
          } @else {
            <span class="title placeholder">Nom du parcours</span>
          }
        </div>
        <div class="point-of-interest-list">
          @for (pointOfInterest of itinerary.pointOfInterests; track $index) {
            @if (selectedLanguageId) {
              <a routerLink="/{{ this.getProjectName() }}/previsualisation/point-of-interest/{{pointOfInterest.id}}">
                <div class="point-of-interest-item">
                  <div class="horizontal-display">
                    <span class="point-of-interest-index">{{$index}}</span>
                    <div>
                      <div style="display: flex;">
                        <img class="module-list-icon" src="assets/images/Liste_Activite_Grey.png" alt="image"/>
                        <span> {{pointOfInterest.countOfModules}} activité(s)</span>
                      </div>
                      <div class="point-of-interest-name">{{ displayName(pointOfInterest) }}</div>
                      @if (!displayName(pointOfInterest)) {
                        <div class="point-of-interest-name placeholder">Nom de l'étape</div>
                      }
                      <div class="point-of-interest-details">{{ displayDetails(pointOfInterest) }}</div>
                      @if (!displayDetails(pointOfInterest)) {
                        <div class="point-of-interest-details placeholder">Détails de l'étape</div>
                      }
                    </div>
                  </div>
                  <i class="bi bi-eye eye"></i>
                </div>
              </a>
            }
          }
        </div>
        @if (itinerary.pointOfInterests.length === 0) {
          <p class="placeholder" style="text-align: center;">Aucune étape n'a été créée dans ce parcours ce parcours</p>
        }
      </div>
    }
  </div>
</div>
